import React from 'react'
import { Doughnut } from 'react-chartjs-2'
import Grid from '@mui/material/Grid'
import { Box, Typography, Button } from '@mui/material/'

const CircularProgressWithLabel = ({ value, months, days, endDate }) => {
    const data = {
        datasets: [
            {
                data: [value, 100 - value],
                backgroundColor: ['#4FD1C5', '#eeeeee'],
                borderWidth: 0,
            },
        ],
    }

    let formattedEndDate = new Date(endDate)

    const options = {
        cutout: '90%',
        circumference: 360,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            tooltip: {
                enabled: false,
            },
        },
    }

    return (
        <Grid
            container
            item
            sx={{
                position: 'relative',
                width: 240, // Adjust to fit the size of the progress bar
                height: 240, // Adjust to fit the size of the progress bar
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
            <Doughnut data={data} options={options} />
            <Grid
                container
                item
                direction="column"
                sx={{
                    position: 'absolute',
                    top: '45%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center',
                }}>
                <Grid item>
                    <Typography variant="title2">ATLAS Free Trial</Typography>
                </Grid>
                <Grid container item wrap="nowrap">
                    <Grid container item direction="column">
                        <Grid>
                            <Typography variant="body7" color="primary">
                                {months}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h5">months</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item direction="column">
                        <Grid>
                            <Typography variant="body7" color="primary">
                                {days}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h5">days</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                container
                item
                sx={{
                    position: 'absolute',
                    top: '70%',
                    left: '20%',
                    textAlign: 'center',
                }}>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    alignContent="start"
                    style={{ marginLeft: '22px' }}>
                    <Grid item xs="auto">
                        <Typography variant="subtitle5">Upgrade By</Typography>
                    </Grid>
                    <Grid item xs="auto">
                        <Typography variant="subtitle5">{formattedEndDate.toDateString()}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

const SubscriptionStatus = (props) => {
    const months = props.months
    const days = props.days
    const value = props.percent
    const endDate = props.endDate

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
            <CircularProgressWithLabel value={value} months={months} days={days} endDate={endDate} />
        </Box>
    )
}

export default SubscriptionStatus
