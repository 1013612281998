import React, { useEffect, useState } from 'react'
import './dashboard.scss'
import { DialogActions, DialogContent, DialogTitle, useStyles, StyledTableRow } from './dashboard-style'
import { useHover } from '../Support/CustomElements'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import SvgIcon from '@mui/material/SvgIcon'
import { styled } from '@mui/material/styles'
import { ReactComponent as AddCircle } from '../../assets/images/add-circle.svg'
import { ReactComponent as PencilIcon } from '../../assets/images/pencil.svg'
import { ReactComponent as Download } from '../../assets/images/download-bottom.svg'
import { ReactComponent as DeleteBin } from '../../assets/images/bin.svg'
import { ReactComponent as UploadIcon } from '../../assets/images/upload-bottom.svg'
import CloseIcon from '@mui/icons-material/CloseOutlined'
import { Divider, Icon, IconButton } from '@mui/material/'
import Chip from '@mui/material/Chip'
import List from '@mui/material/List'
import Paper from '@mui/material/Paper'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContentText from '@mui/material/DialogContentText'
import TextField from '@mui/material/TextField'
import Fade from '@mui/material/Fade'
import Typography from '@mui/material/Typography'
import { setStrategy, setUserRecord } from '../../redux/actions'
import { connect, useSelector } from 'react-redux'
import * as _ from 'underscore'
import { countries } from '../../utilities/countries'
import Autocomplete from '@mui/material/Autocomplete'
import EditableMarketList from './EditableMarketList'
import store from '../../redux/store'
import { useAuth0 } from '../../contexts/auth0-context'
import callAPI from '../../API/callSecureAPI'
import endpoints from '../../API/endpoints'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import ClearIcon from '@mui/icons-material/Clear'
import TableHead from '@mui/material/TableHead'
import InputAdornment from '@mui/material/InputAdornment'
import callSecureAPI from '../../API/callSecureAPI'
import { uploadFile } from '../../API/PostRequests'
import ReactCountryFlag from 'react-country-flag'
import { Check, CancelOutlined } from '@mui/icons-material'
import { ReactComponent as MapIcon } from '../../assets/images/maps-mark.svg'

function mapDispatchToProps(dispatch) {
    return {
        setStrategy: (strat) => dispatch(setStrategy(strat)),
        setUserRecord: (userRecord) => dispatch(setUserRecord(userRecord)),
    }
}

function ConnectedDashboard(props) {
    const snapshotMode = useSelector((state) => (state.strategy ? !!state.strategy.sourceId : false))
    const userReadonly = useSelector((state) => (state.userRecord ? !!state.userRecord.readonly : false))
    const isDarkMode = useSelector((state) =>
        !state.userRecord ? true : state?.userRecord?.isDarkMode === undefined ? true : state?.userRecord?.isDarkMode
    )

    const { user, getTokenSilently, isAuthenticated, loading, logout } = useAuth0()
    const history = useHistory()
    const classes = useStyles()
    const [selectedFiles, setSelectedFiles] = useState([])

    const [openTPP, setOpenTPP] = useState(false)
    const [openCompetitiveLandscape, setOpenCompetitiveLandscape] = useState(false)
    const [openCriticalSuccess, setOpenCriticalSuccess] = useState(false)
    const [openSnapshot, setOpenSnapshot] = useState(false)
    const [openMarket, setOpenMarket] = useState(false)
    const [open5thButton, setOpen5thButton] = useState(false)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)

    const [newSnapshotNote, setNewSnapshotNote] = useState('')
    const [snapshotInProgress, setSnapshotInProgress] = useState(false)

    const [editing, setEditing] = useState(false)
    const [newPositioningStatement, setNewPositioningStatement] = useState('')
    const [positioningStatement, setPositioningStatement] = useState('')
    const [strategy, setStrat] = useState({})
    const [loaded, setLoaded] = useState(false)
    const [hoveredFieldId, setHoveredFieldId] = useState(null)
    const [authenticated, setAuthenticated] = useState(false)

    const [competitiveLandscape, setCompetitiveLandscape] = useState([])
    const [newCompetitiveLandscapes, setNewCompetitiveLandscapes] = useState([])
    const [keyMarkets, setKeyMarkets] = useState([])
    const [newKeyMarket, setNewKeyMarket] = useState({})
    const [newKeyMarkets, setNewKeyMarkets] = useState([])

    const [newKeyMarketPhase, setNewKeyMarketPhase] = useState('')
    const [isEditingPhaseTitle, setIsEditingPhaseTitle] = useState(false)
    const [editedPhaseTitlePosition, setEditedPhaseTitlePosition] = useState(null)

    const [strategicImperativeMap, setStrategicImperativeMap] = useState({})

    const [criticalSuccessFactors, setCriticalSuccessFactors] = useState([])
    const [newCriticalSuccessFactor, setNewCriticalSuccessFactor] = useState('')
    const [newCriticalSuccessFactors, setNewCriticalSuccessFactors] = useState([])
    const [criticalSuccessFactorsMap, setCriticalSuccessFactorsMap] = useState({})

    const [fifthButtonValues, setFifthButtonValues] = useState([])
    const [newFifthButtonValues, setNewFifthButtonValues] = useState([])
    const [newFifthButtonItem, setNewFifthButtonItem] = useState('')
    const [fifthButtonMap, setFifthButtonMap] = useState({})

    const [gapAnalysis, setGapAnalysis] = useState({})
    const [marketAccessGoals, setMarketAccessGoals] = useState([])
    const [newMarketAccessGoals, setNewMarketAccessGoals] = useState([])
    const [newMarketAccessGoalText, setNewMarketAccessGoalText] = useState('')
    const [marketAccessGoalsMap, setMarketAccessGoalsMap] = useState({})

    const [TPP, setTPP] = useState([])
    const [newTPP, setNewTPP] = useState([])

    const [forceRefresh, setForceRefresh] = useState(false)

    const [vms, setVMs] = useState([])

    const [toDelete, setToDelete] = useState([])

    const [snapshotSaved, setSnapshotSaved] = useState(false)
    const [gapAnalysisVisible, setGapAnalysisVisible] = useState(true)

    const [TPPFiles, setTPPFiles] = useState([])
    const [newTPPFiles, setNewTPPFiles] = useState([])
    const [TPPFile, setTPPFile] = useState({})
    const [TPPInfo, setTPPInfo] = useState({})

    const [competitiveLandscapeFiles, setCompetitiveLandscapeFiles] = useState([])
    const [newCompetitiveLandscapeFiles, setNewCompetitiveLandscapeFiles] = useState([])
    const [competitiveLandscapeFile, setCompetitiveLandscapeFile] = useState({})
    const [competitiveLandscapeInfo, setCompetitiveLandscapeInfo] = useState({})

    const [marketAccessGoalsFiles, setMarketAccessGoalsFiles] = useState([])
    const [newMarketAccessGoalsFiles, setNewMarketAccessGoalsFiles] = useState([])
    const [marketAccessGoalsFile, setMarketAccessGoalsFile] = useState({})
    const [marketAccessGoalsInfo, setMarketAccessGoalsInfo] = useState({})

    const [criticalSuccessFactorsFiles, setCriticalSuccessFactorsFiles] = useState([])
    const [newCriticalSuccessFactorsFiles, setNewCriticalSuccessFactorsFiles] = useState([])
    const [criticalSuccessFactorsFile, setCriticalSuccessFactorsFile] = useState({})
    const [criticalSuccessFactorsInfo, setCriticalSuccessFactorsInfo] = useState({})

    const [optionalFifthBoxFiles, setOptionalFifthBoxFiles] = useState([])
    const [newOptionalFifthBoxFiles, setNewOptionalFifthBoxFiles] = useState([])
    const [optionalFifthBoxFile, setOptionalFifthBoxFile] = useState({})
    const [optionalFifthBoxInfo, setOptionalFifthBoxInfo] = useState({})

    const selectedStrategy = useSelector((state) => state.strategy)

    useEffect(() => {
        if (!store.getState().strategy) {
            console.log('No selected strategy.')
            history.push('/roadmaps')
        }
    }, [history, selectedStrategy])

    useEffect(() => {
        isAuthenticated && initGapAnalysisVisible()
        if (store.getState().strategy && isAuthenticated && gapAnalysisVisible) {
            getVMData()
            getGapAnalysis()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, gapAnalysisVisible])

    async function initGapAnalysisVisible() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        let org = (
            await callSecureAPI(
                endpoints.getOrganizationById.url + store.getState().strategy.organizationId,
                endpoints.getOrganizationById.method,
                token
            )
        ).data
        if (org && org.hideMenuItem && org.hideMenuItem['Gap Analyses']) {
            setGapAnalysisVisible(false)
        }
    }

    async function getVMData() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        let vms = await callAPI(
            endpoints.getValueMessagesForTable.url + store.getState().strategy._id,
            endpoints.getValueMessagesForTable.method,
            token
        )
        if (vms.success) {
            setVMs(vms.data)
        }
    }

    async function getGapAnalysis() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        let gap = await callAPI(
            endpoints.getGapAnalysis.url + store.getState().strategy._id,
            endpoints.getGapAnalysis.method,
            token
        )
        if (gap.success) {
            setGapAnalysis(gap.data)
            setMarketAccessGoals(gap.data.goals)
            setNewMarketAccessGoals(gap.data.goals)
            setNewMarketAccessGoalText('')
        }
    }

    useEffect(() => {
        // This fires when component mounts and any time positioningStatement is modified,
        // updating the Strategy object, as well as the data fields

        if (forceRefresh) {
            setForceRefresh(false)
        }

        let selectedStrategy = store.getState().strategy
        if (!selectedStrategy) {
            history.push('/roadmaps')
        } else {
            if (!_.isEmpty(user)) {
                getUser()
            }

            async function getUser() {
                let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
                let parsedUserId = user.sub.split('|')
                if (parsedUserId.length >= 2) {
                    let userId = parsedUserId.slice(1).join('|')
                    let userData = await callAPI(
                        endpoints.getUserAuth0.url + userId + '/auth0',
                        endpoints.getUserAuth0.method,
                        token
                    )
                    if (userData.data?.organizationId !== selectedStrategy.organizationId) {
                        history.push('/roadmaps')
                    } else {
                        setAuthenticated(true)
                        props.setUserRecord(userData.data)
                    }
                }
            }

            async function getStrategyData() {
                let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
                let strategy = await callAPI(
                    endpoints.getStrategy.url + store.getState().strategy._id,
                    endpoints.getStrategy.method,
                    token
                )
                if (!strategy.success) {
                    return
                }
                setStrat(strategy.data)
                props.setStrategy(strategy.data)
                setPositioningStatement(strategy.data.positioningStatement)
                setNewPositioningStatement(strategy.data.positioningStatement)
                setCompetitiveLandscape(strategy.data.competitiveLandscape)
                setNewCompetitiveLandscapes(strategy.data.competitiveLandscape)
                setCriticalSuccessFactors(strategy.data.criticalSuccessFactors)
                setNewCriticalSuccessFactors(strategy.data.criticalSuccessFactors)
                setNewCriticalSuccessFactor('')
                setTPP(strategy.data.TPP)
                setNewTPP(strategy.data.TPP)
                setKeyMarkets(strategy.data.keyMarkets)
                setNewKeyMarket({})
                setNewKeyMarkets(strategy.data.keyMarkets)
                setFifthButtonValues(strategy.data.optionalFifthBoxDashboard)
                setNewFifthButtonValues(strategy.data.optionalFifthBoxDashboard)
                setNewFifthButtonItem('')
                setTPPFiles(strategy.data.TPPFiles)
                setNewTPPFiles(strategy.data.TPPFiles)
                setCompetitiveLandscapeFiles(strategy.data.competitiveLandscapeFiles)
                setNewCompetitiveLandscapeFiles(strategy.data.competitiveLandscapeFiles)
                setMarketAccessGoalsFiles(strategy.data.marketAccessGoalsFiles)
                setNewMarketAccessGoalsFiles(strategy.data.marketAccessGoalsFiles)
                setCriticalSuccessFactorsFiles(strategy.data.criticalSuccessFactorsFiles)
                setNewCriticalSuccessFactorsFiles(strategy.data.criticalSuccessFactorsFiles)
                setOptionalFifthBoxFiles(strategy.data.optionalFifthBoxFiles)
                setNewOptionalFifthBoxFiles(strategy.data.optionalFifthBoxFiles)
                setLoaded(true)
                buildStrategicImperativesMap(strategy.data.strategicImperatives)
                buildCriticalSuccessFactorMap(strategy.data.criticalSuccessFactors)
                buildFifthButtonValuesMap(strategy.data.optionalFifthBoxDashboard)
            }

            if (!loading && isAuthenticated && store.getState().strategy) {
                getStrategyData()
            }
        }
    }, [forceRefresh, props, isAuthenticated, loading, getTokenSilently, history, user])

    async function savePositioningStatement() {
        setEditing(false)
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        await callAPI(endpoints.updateStrategy.url + strategy._id, endpoints.updateStrategy.method, token, {
            positioningStatement: newPositioningStatement,
            product: strategy.product,
            indication: strategy.indication,
            organizationId: strategy.organizationId,
            TPPS3Name: strategy.TPPS3Name,
            TPPMimeType: strategy.TPPMimeType,
            TPPOriginalFileName: strategy.TPPOriginalFileName,
            keyMarkets: newKeyMarkets,
            targetLaunchDate: {},
            competitiveLandscape: newCompetitiveLandscapes,
            strategicImperatives: Object.values(strategicImperativeMap),
            TPP: newTPP,
            criticalSuccessFactors: Object.values(criticalSuccessFactorsMap),
            valueStatements: strategy.valueStatements,
            optionalFifthBoxDashboard: Object.values(fifthButtonMap),
            TPPFiles: newTPPFiles,
            competitiveLandscapeFiles: newCompetitiveLandscapeFiles,
            marketAccessGoalsFiles: newMarketAccessGoalsFiles,
            optionalFifthBoxFiles: newOptionalFifthBoxFiles,
            criticalSuccessFactorsFiles: newCriticalSuccessFactorsFiles,
            gapLevers: strategy.gapLevers,
        })
        if (!_.isEmpty(TPPFile)) {
            uploadFile(TPPFile, TPPInfo?.s3Name, TPPInfo?.atlasFileType, token)
        } else if (!_.isEmpty(competitiveLandscapeFile)) {
            uploadFile(
                competitiveLandscapeFile,
                competitiveLandscapeInfo?.s3Name,
                competitiveLandscapeInfo?.atlasFileType,
                token
            )
        } else if (!_.isEmpty(marketAccessGoalsFile)) {
            uploadFile(
                marketAccessGoalsFile,
                marketAccessGoalsInfo?.s3Name,
                marketAccessGoalsInfo?.atlasFileType,
                token
            )
        } else if (!_.isEmpty(optionalFifthBoxFile)) {
            uploadFile(optionalFifthBoxFile, optionalFifthBoxInfo?.s3Name, optionalFifthBoxInfo?.atlasFileType, token)
        } else if (!_.isEmpty(criticalSuccessFactorsFile)) {
            uploadFile(
                criticalSuccessFactorsFile,
                criticalSuccessFactorsInfo?.s3Name,
                criticalSuccessFactorsInfo?.atlasFileType,
                token
            )
        }
        setForceRefresh(true)
        setPositioningStatement(newPositioningStatement)
        setKeyMarkets(newKeyMarkets)
        setCompetitiveLandscape(newCompetitiveLandscapes)
        setTPP(newTPP)
        setCriticalSuccessFactors(newCriticalSuccessFactors)
        setFifthButtonValues(newFifthButtonValues)
    }

    function handleCancel() {
        setNewPositioningStatement(positioningStatement)
        setNewKeyMarket({})
        setNewKeyMarkets(keyMarkets)
        setEditing(false)
    }

    function onCountryChange(object, value) {
        setNewKeyMarket(value)
    }

    function handleNewMarket(e) {
        e.preventDefault()
        if (_.isEmpty(newKeyMarket)) {
            return
        }
        let newArr = Array.from(newKeyMarkets)
        let newMarketArr = Array.from(newKeyMarkets[editedPhaseTitlePosition].phaseKeyMarkets)
        newMarketArr.push({ code: newKeyMarket.code, label: newKeyMarket.label, targetLaunchDate: '' })
        newArr.splice(editedPhaseTitlePosition, 1, {
            phaseTitle: newKeyMarkets[editedPhaseTitlePosition].phaseTitle,
            phaseKeyMarkets: newMarketArr,
        })
        setNewKeyMarkets(newArr)
        setNewKeyMarket({})
    }

    function countryToFlag(isoCode) {
        return typeof String.fromCodePoint !== 'undefined'
            ? isoCode.replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
            : isoCode
    }

    const handleTPPClickOpen = () => {
        setOpenTPP(true)
    }
    const handleTPPClose = async () => {
        setOpenTPP(false)
        setTPPFile({})
        setTPPInfo({})
        setNewTPPFiles(TPPFiles)
        await new Promise((r) => setTimeout(r, 500))
        setNewTPP(TPP)
        setSelectedFiles([])
    }

    const handleTPPSave = async () => {
        savePositioningStatement()
        setTPPFile({})
        setTPPInfo({})
        setOpenTPP(false)
        setSelectedFiles([])
    }

    function onTPPFileChange(e) {
        let newFiles = Array.from(e.target.files)
        let newTPPFileInfos = newFiles.map((file) => {
            let timestamp = Date.now().toString()
            return {
                s3Name: timestamp,
                mimeType: file.type,
                originalFileName: file.name,
                atlasFileType: 'TPP',
            }
        })

        setSelectedFiles([...selectedFiles, ...newFiles.map((file) => file.name)])
        setNewTPPFiles([...newTPPFiles, ...newTPPFileInfos])
    }

    const handleDeleteFile = (s3Name) => {
        const index = newTPPFiles.findIndex((file) => file.s3Name === s3Name)

        if (index === -1) {
            console.error('File not found in newTPPFiles array')
            return
        }

        const updatedSelectedFiles = [...selectedFiles]
        updatedSelectedFiles.splice(index, 1)
        setSelectedFiles(updatedSelectedFiles)

        const updatedNewTPPFiles = [...newTPPFiles]
        updatedNewTPPFiles.splice(index, 1)
        setNewTPPFiles(updatedNewTPPFiles)
    }

    const handleCompetitiveClickOpen = () => {
        setOpenCompetitiveLandscape(true)
    }

    const handleCompetitiveClose = async () => {
        setOpenCompetitiveLandscape(false)
        setCompetitiveLandscapeFile({})
        setCompetitiveLandscapeInfo({})
        setNewCompetitiveLandscapeFiles(competitiveLandscapeFiles)
        await new Promise((r) => setTimeout(r, 500))
        setNewCompetitiveLandscapes(competitiveLandscape)
    }

    const handleCompetitiveLandscapeSave = () => {
        savePositioningStatement()
        setCompetitiveLandscapeFile({})
        setCompetitiveLandscapeInfo({})
        setOpenCompetitiveLandscape(false)
    }

    function onCompetitiveLandscapeFileChange(e) {
        setCompetitiveLandscapeFile({ link: e.target.files[0] })
        let timestamp = Date.now().toString()
        let fileInfo = {
            s3Name: timestamp,
            mimeType: e.target.files[0].type,
            originalFileName: e.target.files[0].name,
            atlasFileType: 'competitiveLandscape',
        }
        setCompetitiveLandscapeInfo(fileInfo)
        setNewCompetitiveLandscapeFiles([...newCompetitiveLandscapeFiles, fileInfo])
    }

    const handleMarketOpen = () => {
        setOpenMarket(true)
    }

    const handleMarketClose = async () => {
        setNewMarketAccessGoalText('')
        setOpenMarket(false)
        setMarketAccessGoalsFile({})
        setMarketAccessGoalsInfo({})
        setNewMarketAccessGoalsFiles(marketAccessGoalsFiles)
        await new Promise((r) => setTimeout(r, 500))
        buildMarketAccessGoalMap(marketAccessGoals)
        setNewMarketAccessGoals(marketAccessGoals)
    }

    const handleMarketSave = async () => {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })

        let newGap = {
            overallSummaryRating: gapAnalysis.overallSummaryRating,
            description: gapAnalysis.description,
            goals: newMarketAccessGoals,
            strategyId: gapAnalysis.strategyId,
            organizationId: gapAnalysis.organizationId,
            gapAssessment: gapAnalysis.gapAssessment,
            reimbursableFile: gapAnalysis.reimbursableFile,
        }
        await callAPI(
            endpoints.updateGapAnalysis.url + gapAnalysis._id,
            endpoints.updateGapAnalysis.method,
            token,
            newGap
        )
        savePositioningStatement()
        setMarketAccessGoalsFile({})
        setMarketAccessGoalsInfo({})
        setMarketAccessGoals(newMarketAccessGoals)
        setOpenMarket(false)
    }

    function onMarketAccessGoalsFileChange(e) {
        setMarketAccessGoalsFile({ link: e.target.files[0] })
        let timestamp = Date.now().toString()
        let fileInfo = {
            s3Name: timestamp,
            mimeType: e.target.files[0].type,
            originalFileName: e.target.files[0].name,
            atlasFileType: 'marketAccessGoals',
        }
        setMarketAccessGoalsInfo(fileInfo)
        setNewMarketAccessGoalsFiles([...newMarketAccessGoalsFiles, fileInfo])
    }

    function buildMarketAccessGoalMap(mags) {
        let newObj = {}
        for (let mag of mags) {
            newObj[mag.title] = mag.title
        }
        setMarketAccessGoalsMap(newObj)
    }

    function buildStrategicImperativesMap(strats) {
        let newObj = {}
        for (let strat of strats) {
            newObj[strat] = strat
        }
        setStrategicImperativeMap(newObj)
    }

    function buildCriticalSuccessFactorMap(factors) {
        let newObj = {}
        for (let factor of factors) {
            newObj[factor] = factor
        }
        setCriticalSuccessFactorsMap(newObj)
    }

    function buildFifthButtonValuesMap(values) {
        let newObj = {}
        for (let value of values) {
            newObj[value] = value
        }
        setFifthButtonMap(newObj)
    }

    function updateFifthButtonValuesMap(event, row) {
        let newObj = { ...fifthButtonMap }
        newObj[row] = event
        setFifthButtonMap(newObj)
    }

    const handleFifthButtonsSave = () => {
        savePositioningStatement()
        setOptionalFifthBoxFile({})
        setOptionalFifthBoxInfo({})
        setOpen5thButton(false)
    }

    function onOptionalFifthBoxFileChange(e) {
        setOptionalFifthBoxFile({ link: e.target.files[0] })
        let timestamp = Date.now().toString()
        let fileInfo = {
            s3Name: timestamp,
            mimeType: e.target.files[0].type,
            originalFileName: e.target.files[0].name,
            atlasFileType: 'optionalFifthBox',
        }
        setOptionalFifthBoxInfo(fileInfo)
        setNewOptionalFifthBoxFiles([...newOptionalFifthBoxFiles, fileInfo])
    }

    const handleFifthButtonClickOpen = () => {
        setOpen5thButton(true)
    }

    const handleFifthButtonClickClose = async () => {
        setOpen5thButton(false)
        setOptionalFifthBoxFile({})
        setOptionalFifthBoxInfo({})
        setNewOptionalFifthBoxFiles(optionalFifthBoxFiles)
        // Added this 'sleep' so that there is no jerky update as the modal closes
        await new Promise((r) => setTimeout(r, 500))
        buildFifthButtonValuesMap(fifthButtonValues)
        setNewFifthButtonValues(fifthButtonValues)
    }

    function updateCriticalSuccessFactorsMap(event, row) {
        let newObj = { ...criticalSuccessFactorsMap }
        newObj[row] = event
        setCriticalSuccessFactorsMap(newObj)
    }

    const handleCriticalSuccessSave = () => {
        savePositioningStatement()
        setCriticalSuccessFactorsFile({})
        setCriticalSuccessFactorsInfo({})
        setOpenCriticalSuccess(false)
    }

    const handleCriticalSuccessClickOpen = () => {
        setOpenCriticalSuccess(true)
    }

    const handleCriticalSuccessClose = async () => {
        setOpenCriticalSuccess(false)
        setCriticalSuccessFactorsFile({})
        setCriticalSuccessFactorsInfo({})
        setNewCriticalSuccessFactorsFiles(criticalSuccessFactorsFiles)
        // Added this 'sleep' so that there is no jerky update as the modal closes
        await new Promise((r) => setTimeout(r, 500))
        buildCriticalSuccessFactorMap(criticalSuccessFactors)
        setNewCriticalSuccessFactors(criticalSuccessFactors)
    }

    function onCriticalSuccessFactorsFileChange(e) {
        setCriticalSuccessFactorsFile({ link: e.target.files[0] })
        let timestamp = Date.now().toString()
        let fileInfo = {
            s3Name: timestamp,
            mimeType: e.target.files[0].type,
            originalFileName: e.target.files[0].name,
            atlasFileType: 'criticalSuccessFactors',
        }
        setCriticalSuccessFactorsInfo(fileInfo)
        setNewCriticalSuccessFactorsFiles([...newCriticalSuccessFactorsFiles, fileInfo])
    }

    const handleSnapshotCreate = async () => {
        setSnapshotInProgress(true)
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        let result = await callAPI(endpoints.createStrategy.url, endpoints.createStrategy.method, token, {
            snapshotSourceId: strategy._id,
            snapshotNote: newSnapshotNote,
        })
        if (result.success) {
            setOpenSnapshot(false)
            setSnapshotSaved(true)
        } else {
            alert('Create snapshot failed')
        }
        setSnapshotInProgress(false)
    }

    const setCurrentStrategy = async () => {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        let result = await callAPI(endpoints.getStrategy.url + strategy.sourceId, endpoints.getStrategy.method, token)
        props.setStrategy(result.data)
        setForceRefresh(true)
    }

    async function removeFile(s3Name, fileType) {
        // :id/removeFile/:s3Name/:fileType
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        await callAPI(
            endpoints.removeStrategyFile.url + strategy._id + '/removeFile/' + s3Name + '/' + fileType,
            endpoints.removeStrategyFile.method,
            token
        )
        setForceRefresh(true)
        setOpenDeleteModal(false)
    }

    let anchors = [
        {
            title: _.isEmpty(store.getState().settings) ? '' : store.getState().settings.displayNames.tpp,
            action: handleTPPClickOpen,
        },
        {
            title: 'Competitive Landscape',
            action: handleCompetitiveClickOpen,
        },
        {
            title: store.getState().settings?.displayNames?.criticalSuccessFactors || 'Loading',
            action: handleCriticalSuccessClickOpen,
        },
        {
            title:
                (store.getState().settings?.displayNames?.marketAccessGoals &&
                    store.getState().settings?.displayNames?.marketAccessGoals) ||
                'Loading',
            action: handleMarketOpen,
            condition: () => gapAnalysisVisible,
        },
        {
            title:
                (store.getState().settings?.displayNames?.fifthBoxDashboard &&
                    store.getState().settings?.displayNames?.fifthBoxDashboard) ||
                'Loading',
            action: handleFifthButtonClickOpen,
            condition: () => store.getState().settings?.showFifthBoxDashboard,
        },
    ]

    let filteredAnchors = anchors.filter((anchor) => !anchor.condition || anchor.condition())

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: 'rgba(56, 155, 255, .05)',
        border: '1px solid rgba(56, 155, 255, .5)',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        // color: theme.palette.text.primary,
    }))

    const handleEditOpen = () => {
        setOpenEdit(true)
    }
    const handleEditCancel = () => {
        setOpenEdit(false)
        setIsEditingPhaseTitle(false)
        setEditedPhaseTitlePosition(null)
        setNewKeyMarkets(keyMarkets)
    }

    const handleEditSave = () => {
        setOpenEdit(false)
        setIsEditingPhaseTitle(false)
        setEditedPhaseTitlePosition(null)
        savePositioningStatement()
    }

    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }))

    const [hover, mouseEvents] = useHover()

    return (
        <Fade in={loaded && authenticated} timeout={800}>
            <Grid container item p={2}>
                <Grid container item wrap="nowrap" justifyContent="left" spacing={1.5} alignItems="baseline" sx={{ mb: 4 }}>
                    <Grid item>
                        <SvgIcon
                        component={MapIcon}
                        />
                    </Grid>
                    <Grid item xs>
                        <Typography variant="h1">
                            {strategy.product} for {strategy.indication}
                        </Typography>
                    </Grid>
                    <Grid container item xs="auto">
                        {editing ? (
                            <>
                                <Button
                                    aria-label="cancel"
                                    autoFocus
                                    variant="outlined"
                                    onClick={handleCancel}
                                    color="primary"
                                    sx={{ mr: 1 }}>
                                    Cancel
                                </Button>
                                <Button
                                    aria-label="save"
                                    autoFocus
                                    variant="contained"
                                    color="primary"
                                    onClick={savePositioningStatement}>
                                    Save
                                </Button>
                            </>
                        ) : (
                            !snapshotMode &&
                            !userReadonly && (
                                <Button
                                    autoFocus
                                    id="edit-button"
                                    variant="contained"
                                    startIcon={<SvgIcon component={PencilIcon} alt="Pencil Icon" inheritViewBox />}
                                    onClick={handleEditOpen}
                                    style = {{ width: 168, 
                                            height:52,
                                            borderRadius: 14
                                            }}
                                    >
                                    Edit
                                </Button>
                            )
                        )}
                    </Grid>
                </Grid>
                <Grid container item justifyContent="flex-start" alignItems="flex-start" spacing={3}>
                    <Grid container item spacing={3} justifyContent="flex-start" alignItems="flex-start">
                        <Grid container item direction="column" spacing={3} xs={12} md={6}>
                            <Grid item>
                                <Typography variant="h2">
                                    {store.getState().settings?.displayNames?.valueProposition || 'Loading'}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body1" sx={{ whiteSpace: 'break-spaces' }}>
                                    {positioningStatement}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} md={6} spacing={3}>
                            <Grid item>
                                <Typography variant="h2">Strategic Anchors</Typography>
                            </Grid>
                            <Grid container item spacing={3}>
                                {filteredAnchors.map((anchor, i) => (
                                    <Grid item xs={6}>
                                        <Item component={Paper} sx={{border: 1, borderColor: "white", borderRadius: 3}}>
                                            <Grid
                                                container
                                                alignItems="center"
                                                justifyContent="space-between"
                                                wrap="nowrap">
                                                <Grid item>
                                                    <Typography variant="h5">{anchor.title}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <IconButton aria-label="see more" onClick={anchor.action}>
                                                        <ArrowForwardIosIcon
                                                            color="secondary"
                                                            sx={{
                                                                    '&:hover': {
                                                                        color: 'primary',
                                                                    },
                                                            }}
                                                        />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Item>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item spacing={3} direction="column">
                        <Grid item xs={12}>
                            <Typography variant="h2">Key Markets & Target Launch Dates</Typography>
                        </Grid>
                        <EditableMarketList items={newKeyMarkets} updateMaster={setNewKeyMarkets} />
                    </Grid>
                </Grid>
                <Dialog
                    onClose={handleEditCancel}
                    aria-labelledby="product-dialog-box"
                    open={openEdit}
                    fullWidth
                    maxWidth="lg">
                    <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                        <Typography variant="h1">Product Details</Typography>
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleEditCancel}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}>
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers>
                        <Grid container spacing={2}>
                            <Grid container item direction="column">
                                <Grid item>
                                    <Typography variant="h2">Value Proposition</Typography>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        id="positioning-statement"
                                        multiline
                                        variant="outlined"
                                        hiddenLabel
                                        fullWidth
                                        minRows={4}
                                        placeholder={positioningStatement}
                                        value={newPositioningStatement}
                                        onFocus={(event) => {
                                            event.target.setAttribute('autocomplete', 'off')
                                        }}
                                        onChange={(e) => setNewPositioningStatement(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item direction="column">
                                <Grid item mb={2}>
                                    <Typography variant="h2">Key Markets & Target Launch Dates</Typography>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        id="new-phase"
                                        variant="outlined"
                                        fullWidth
                                        InputProps={
                                            newKeyMarketPhase.length > 0
                                                ? {
                                                      endAdornment: (
                                                          <InputAdornment
                                                              position="end"
                                                              onClick={() => {
                                                                  let newArr = Array.from(newKeyMarkets)
                                                                  newArr.push({
                                                                      phaseTitle: newKeyMarketPhase,
                                                                      phaseKeyMarkets: [],
                                                                  })
                                                                  setNewKeyMarkets(newArr)
                                                                  setNewKeyMarketPhase('')
                                                              }}>
                                                              <SvgIcon component={AddCircle} color="primary" />
                                                          </InputAdornment>
                                                      ),
                                                  }
                                                : {}
                                        }
                                        onChange={(e) => setNewKeyMarketPhase(e.target.value)}
                                        value={newKeyMarketPhase}
                                        label="Add new phase"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs spacing={2}>
                                {newKeyMarkets.map((phase, i) => (
                                    <Grid
                                        item
                                        xs={6}
                                        sx={{
                                            backgroundColor: isDarkMode ? '#1A2027' : '',
                                        }}>
                                        {/* -----------  PHASE TITLE -----------    */}

                                        {isEditingPhaseTitle && i === editedPhaseTitlePosition ? (
                                            <Grid container item direction="column">
                                                <Grid item>
                                                    <Typography variant="subtitle3">Edit phase title</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        id="new-phase-title"
                                                        variant="filled"
                                                        fullWidth
                                                        size="small"
                                                        onChange={(e) => {
                                                            let newArr = Array.from(newKeyMarkets)
                                                            newArr.splice(i, 1, {
                                                                phaseTitle: e.target.value,
                                                                phaseKeyMarkets: newKeyMarkets[i].phaseKeyMarkets,
                                                            })
                                                            setNewKeyMarkets(newArr)
                                                        }}
                                                        value={newKeyMarkets[i].phaseTitle}
                                                        onMouseEnter={() => setHoveredFieldId(`phaseTitle-${i}`)}
                                                        onMouseLeave={() => setHoveredFieldId(null)}
                                                        InputProps={{
                                                            endAdornment:
                                                                hoveredFieldId === `phaseTitle-${i}` ? (
                                                                    <>
                                                                        <InputAdornment position="end">
                                                                            <IconButton
                                                                                color="primary"
                                                                                aria-label="save"
                                                                                onClick={() => {
                                                                                    setEditedPhaseTitlePosition(null)
                                                                                    setIsEditingPhaseTitle(false)
                                                                                }}>
                                                                                <SvgIcon component={Check} />
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                        <InputAdornment position="end">
                                                                            <IconButton
                                                                                color="primary"
                                                                                aria-label="delete"
                                                                                onClick={() => {
                                                                                    let newArr = Array.from(
                                                                                        newKeyMarkets
                                                                                    )
                                                                                    newArr.splice(i, 1)
                                                                                    setNewKeyMarkets(newArr)
                                                                                    setIsEditingPhaseTitle(false)
                                                                                    setEditedPhaseTitlePosition(null)
                                                                                }}>
                                                                                <SvgIcon component={DeleteBin} />
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <InputAdornment position="end">
                                                                            <IconButton
                                                                                color="#C2E0FF"
                                                                                aria-label="save"
                                                                                onClick={() => {
                                                                                    setEditedPhaseTitlePosition(null)
                                                                                    setIsEditingPhaseTitle(false)
                                                                                }}>
                                                                                <SvgIcon component={Check} />
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                        <InputAdornment position="end">
                                                                            <IconButton
                                                                                aria-label="delete"
                                                                                color="#C2E0FF"
                                                                                onClick={() => {
                                                                                    let newArr = Array.from(
                                                                                        newKeyMarkets
                                                                                    )
                                                                                    newArr.splice(i, 1)
                                                                                    setNewKeyMarkets(newArr)
                                                                                    setIsEditingPhaseTitle(false)
                                                                                    setEditedPhaseTitlePosition(null)
                                                                                }}>
                                                                                <SvgIcon component={DeleteBin} />
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    </>
                                                                ),
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <Grid container item alignItems="center" spacing={1}>
                                                <Grid item>
                                                    <Typography variant="h3" color="secondary">
                                                        {phase.phaseTitle}
                                                    </Typography>
                                                </Grid>
                                                {!isEditingPhaseTitle && (
                                                    <Grid item>
                                                        <IconButton
                                                            variant="contained"
                                                            aria-label="edit"
                                                            onClick={() => {
                                                                setIsEditingPhaseTitle(true)
                                                                setEditedPhaseTitlePosition(i)
                                                            }}>
                                                            <SvgIcon
                                                                fontSize="small"
                                                                component={PencilIcon}
                                                                color="primary"
                                                            />
                                                        </IconButton>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        )}
                                        {i === editedPhaseTitlePosition && (
                                            <Grid container item xs>
                                                <Grid item>
                                                    <Typography variant="subtitle3">Add/Remove key markets</Typography>
                                                </Grid>
                                                <Autocomplete
                                                    id="combo-box"
                                                    options={countries}
                                                    autoHighlight
                                                    selectOnFocus
                                                    clearOnBlur
                                                    fullWidth
                                                    handleHomeEndKeys
                                                    getOptionLabel={(option) => option.label}
                                                    onChange={onCountryChange}
                                                    classes={{
                                                        clearIndicatorDirty: classes.clearIndicator,
                                                        popupIndicator: classes.popupIndicator,
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            focused
                                                            label="Select '+' to add to the list"
                                                            onMouseEnter={() => setHoveredFieldId(`country-${i}`)}
                                                            onMouseLeave={() => setHoveredFieldId(null)}
                                                            {...params}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    handleNewMarket(e)
                                                                }
                                                            }}
                                                            variant="standard"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment:
                                                                    hoveredFieldId === `country-${i}` ? (
                                                                        <InputAdornment
                                                                            position="end"
                                                                            onClick={handleNewMarket}>
                                                                            <SvgIcon
                                                                                component={AddCircle}
                                                                                color="primary"
                                                                            />
                                                                        </InputAdornment>
                                                                    ) : null,
                                                            }}
                                                            onFocus={(event) => {
                                                                event.target.setAttribute('autocomplete', 'off')
                                                            }}
                                                        />
                                                    )}
                                                    renderOption={(props, option) => (
                                                        <Box
                                                            component="li"
                                                            sx={{
                                                                '& > img': {
                                                                    mr: 4,
                                                                    flexShrink: 0,
                                                                },
                                                            }}
                                                            {...props}>
                                                            <ReactCountryFlag
                                                                countryCode={option.code?.toLowerCase()}
                                                                svg
                                                                cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                                                cdnSuffix="svg"
                                                                style={{
                                                                    width: '1.3em',
                                                                    height: '1.3em',
                                                                    margin: '10px',
                                                                }}
                                                                className="countryFlagList"
                                                            />
                                                            {option.label}
                                                        </Box>
                                                    )}
                                                />
                                            </Grid>
                                        )}
                                        {/* -----------  PHASE MARKETS -----------    */}
                                        <Grid container item>
                                            <Table>
                                                <TableBody>
                                                    {phase.phaseKeyMarkets.map((market, j) => (
                                                        <StyledTableRow tabIndex={-1} key={market + j}>
                                                            <Grid
                                                                container
                                                                item
                                                                alignItems="center"
                                                                p={1}
                                                                justifyContent="space-between">
                                                                <Grid
                                                                    container
                                                                    item
                                                                    wrap="noWrap"
                                                                    alignItems="center"
                                                                    xs={6}>
                                                                    <Grid item>
                                                                        <ReactCountryFlag
                                                                            countryCode={market.code?.toLowerCase()}
                                                                            svg
                                                                            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                                                            cdnSuffix="svg"
                                                                            style={{
                                                                                width: '1.7em',
                                                                                height: '1.7em',
                                                                                margin: '2px',
                                                                            }}
                                                                            title={market.code}
                                                                            className="countryFlag"
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs zeroMinWidth>
                                                                        <Typography
                                                                            variant="body1"
                                                                            noWrap
                                                                            color="secondary">
                                                                            {market.label}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                                {isEditingPhaseTitle &&
                                                                i === editedPhaseTitlePosition ? (
                                                                    <Grid
                                                                        container
                                                                        item
                                                                        xs={4}
                                                                        wrap="noWrap"
                                                                        alignItems="center">
                                                                        <Grid item>
                                                                            <TextField
                                                                                id="new-target-launch-date"
                                                                                variant="standard"
                                                                                onChange={(e) => {
                                                                                    let newArr = Array.from(
                                                                                        newKeyMarkets
                                                                                    )
                                                                                    let newMarketArr = Array.from(
                                                                                        newKeyMarkets[i].phaseKeyMarkets
                                                                                    )
                                                                                    newMarketArr.splice(j, 1, {
                                                                                        code: market.code,
                                                                                        label: market.label,
                                                                                        targetLaunchDate:
                                                                                            e.target.value,
                                                                                    })
                                                                                    newArr.splice(i, 1, {
                                                                                        phaseTitle:
                                                                                            newKeyMarkets[i].phaseTitle,
                                                                                        phaseKeyMarkets: newMarketArr,
                                                                                    })
                                                                                    setNewKeyMarkets(newArr)
                                                                                }}
                                                                                value={
                                                                                    newKeyMarkets[i].phaseKeyMarkets[j]
                                                                                        .targetLaunchDate
                                                                                }
                                                                                label={
                                                                                    <Typography variant="subtitle3">
                                                                                        Target Launch Date
                                                                                    </Typography>
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                ) : (
                                                                    <Grid item>
                                                                        <Typography variant="body2">
                                                                            {market.targetLaunchDate}
                                                                        </Typography>
                                                                    </Grid>
                                                                )}
                                                                <Grid item>
                                                                    <IconButton
                                                                        aria-label="delete"
                                                                        onClick={() => {
                                                                            let newArr = Array.from(newKeyMarkets)
                                                                            let newMarketArr = Array.from(
                                                                                newKeyMarkets[i].phaseKeyMarkets
                                                                            )
                                                                            newMarketArr.splice(j, 1)
                                                                            newArr.splice(i, 1, {
                                                                                phaseTitle: newKeyMarkets[i].phaseTitle,
                                                                                phaseKeyMarkets: newMarketArr,
                                                                            })
                                                                            setNewKeyMarkets(newArr)
                                                                        }}>
                                                                        <SvgIcon component={CancelOutlined} />
                                                                    </IconButton>
                                                                </Grid>
                                                            </Grid>
                                                        </StyledTableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus variant="outlined" onClick={handleEditCancel} color="primary">
                            Cancel
                        </Button>
                        <Button autoFocus variant="contained" onClick={handleEditSave} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    onClose={handleTPPClose}
                    aria-labelledby="customized-dialog-title-tpp"
                    open={openTPP}
                    fullWidth
                    maxWidth="lg">
                    <DialogTitle id="customized-dialog-title-tpp">
                        <Typography variant="h3">
                            {_.isEmpty(store.getState().settings) ? '' : store.getState().settings.displayNames.tpp}
                        </Typography>
                    </DialogTitle>
                    <DialogContent dividers>
                        <Grid container item spacing={2}>
                            {newTPP.map((row, i) => (
                                <Grid container item key={'tppRow' + i} direction="column" xs={6}>
                                    <Grid item>
                                        <Typography variant="title2">{newTPP[i]['displayText']}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            id={'TPP' + i}
                                            disabled={snapshotMode || userReadonly}
                                            multiline
                                            fullWidth
                                            value={newTPP[i]['value']}
                                            onFocus={(event) => {
                                                event.target.setAttribute('autocomplete', 'off')
                                            }}
                                            onChange={(e) => {
                                                let newArr = Array.from(newTPP)
                                                newArr.splice(i, 1, {
                                                    displayText: newTPP[i].displayText,
                                                    value: e.target.value,
                                                })
                                                setNewTPP(newArr)
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                        <DialogTitle id="customized-dialog-title-tpp">
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item>
                                    <Typography variant="title2">Uploaded Files</Typography>
                                </Grid>
                                <Grid item xs="auto">
                                    <List>
                                        {selectedFiles.map((fileName, index) => (
                                            <ListItem key={fileName + index}>
                                                <ListItemText primary={fileName} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Grid>
                                {!userReadonly && !snapshotMode && (
                                    <Grid item xs="auto">
                                        <IconButton component="label">
                                            <input
                                                id="file-upload"
                                                type="file"
                                                hidden
                                                onChange={onTPPFileChange}
                                                multiple
                                            />
                                            <SvgIcon component={AddCircle} color="primary" inheritViewBox />
                                        </IconButton>
                                    </Grid>
                                )}
                            </Grid>
                        </DialogTitle>
                        <Grid container item>
                            {TPPFiles.map((file, i) => (
                                <Grid container spacing={1} alignItems="center" wrap="noWrap">
                                    <Grid item xs="auto">
                                        <Typography variant="chip">{i + 1}.</Typography>
                                    </Grid>
                                    <Grid item xs="auto">
                                        <Chip label={<Typography variant="chip">{file.originalFileName}</Typography>} />
                                    </Grid>
                                    <Grid container item spacing={1} xs="auto">
                                        <Grid item xs="auto">
                                            <IconButton
                                                aria-label="download"
                                                color="primary"
                                                size="small"
                                                href={
                                                    (process.env.REACT_APP_BACKEND_LINK
                                                        ? process.env.REACT_APP_BACKEND_LINK
                                                        : '') +
                                                    '/api/file/any/' +
                                                    strategy._id +
                                                    '/' +
                                                    file.atlasFileType +
                                                    '/' +
                                                    i
                                                }
                                                target="_">
                                                <SvgIcon component={Download} alt="Download Icon" inheritViewBox />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs="auto">
                                            {!userReadonly && !snapshotMode && (
                                                <IconButton
                                                    aria-label="delete"
                                                    color="primary"
                                                    size="small"
                                                    onClick={() => {
                                                        setOpenDeleteModal(true)
                                                        setToDelete([file.s3Name, file.atlasFileType])
                                                    }}>
                                                    <SvgIcon
                                                        component={DeleteBin}
                                                        alt="Delete Trashcan"
                                                        inheritViewBox
                                                    />
                                                </IconButton>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleTPPClose} variant="outlined" color="primary">
                            {snapshotMode || userReadonly ? 'Close' : 'Cancel'}
                        </Button>
                        {!snapshotMode && !userReadonly && (
                            <Button autoFocus onClick={handleTPPSave} variant="contained" color="primary">
                                Save
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>
                <Dialog
                    fullWidth
                    maxWidth="lg"
                    onClose={handleCompetitiveClose}
                    aria-labelledby="customized-dialog-title-competitive"
                    open={openCompetitiveLandscape}>
                    <DialogTitle id="customized-dialog-title-competitive">
                        <Typography variant="h3">Competitive Landscape</Typography>
                    </DialogTitle>
                    <DialogContent dividers>
                        <Grid container item spacing={2}>
                            {Object.keys(newCompetitiveLandscapes).map((row, i) => (
                                <Grid container item key={'compRow' + i} direction="column" xs={6}>
                                    <Grid item>
                                        <Typography variant="title2">
                                            {newCompetitiveLandscapes[row]['displayText']}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            id={'comp' + i}
                                            disabled={snapshotMode || userReadonly}
                                            multiline
                                            fullWidth
                                            placeholder={
                                                !snapshotMode &&
                                                !userReadonly &&
                                                newCompetitiveLandscapes[i]['placeholder']
                                            }
                                            value={newCompetitiveLandscapes[i]['value']}
                                            onFocus={(event) => {
                                                event.target.setAttribute('autocomplete', 'off')
                                            }}
                                            onChange={(e) => {
                                                let newArr = Array.from(newCompetitiveLandscapes)
                                                newArr.splice(i, 1, {
                                                    displayText: newCompetitiveLandscapes[i].displayText,
                                                    value: e.target.value,
                                                    placeholder: newCompetitiveLandscapes[i].placeholder,
                                                })
                                                setNewCompetitiveLandscapes(newArr)
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item>
                                <Typography variant="title2">Uploaded Files</Typography>
                            </Grid>
                            <Grid item xs="auto">
                                <List>
                                    {selectedFiles.map((fileName, index) => (
                                        <ListItem key={index}>
                                            <ListItemText primary={fileName} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Grid>
                            {!userReadonly && !snapshotMode && (
                                <Grid item xs="auto">
                                    <IconButton component="label">
                                        <input
                                            id="file-upload"
                                            type="file"
                                            hidden
                                            onChange={onCompetitiveLandscapeFileChange}
                                            multiple
                                        />
                                        <SvgIcon component={AddCircle} color="primary" inheritViewBox />
                                    </IconButton>
                                </Grid>
                            )}
                        </Grid>
                        <Grid container item>
                            {competitiveLandscapeFiles.map((file, i) => (
                                <Grid container spacing={1} alignItems="center" wrap="nowrap">
                                    <Grid item xs="auto">
                                        <Typography variant="chip">{i + 1}.</Typography>
                                    </Grid>
                                    <Grid item xs="auto">
                                        <Chip label={<Typography variant="chip">{file.originalFileName}</Typography>} />
                                    </Grid>
                                    <Grid container item spacing={1} xs="auto">
                                        <Grid item xs="auto">
                                            <IconButton
                                                aria-label="download"
                                                size="small"
                                                href={
                                                    (process.env.REACT_APP_BACKEND_LINK
                                                        ? process.env.REACT_APP_BACKEND_LINK
                                                        : '') +
                                                    '/api/file/any/' +
                                                    strategy._id +
                                                    '/' +
                                                    file.atlasFileType +
                                                    '/' +
                                                    i
                                                }
                                                target="_">
                                                <SvgIcon component={Download} alt="Download Icon" inheritViewBox />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs="auto">
                                            {!userReadonly && !snapshotMode && (
                                                <IconButton
                                                    aria-label="delete"
                                                    size="small"
                                                    onClick={() => {
                                                        setOpenDeleteModal(true)
                                                        setToDelete([file.s3Name, file.atlasFileType])
                                                    }}>
                                                    <SvgIcon
                                                        component={DeleteBin}
                                                        alt="Delete Trashcan"
                                                        inheritViewBox
                                                    />
                                                </IconButton>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleCompetitiveClose} variant="outlined" color="primary">
                            {snapshotMode || userReadonly ? 'Close' : 'Cancel'}
                        </Button>
                        {!snapshotMode && !userReadonly && (
                            <Button
                                autoFocus
                                onClick={handleCompetitiveLandscapeSave}
                                variant="contained"
                                color="primary">
                                Save
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>
                <Dialog
                    fullWidth
                    maxWidth="lg"
                    onClose={handleCriticalSuccessClose}
                    aria-labelledby="customized-dialog-title-critical-success"
                    open={openCriticalSuccess}>
                    <DialogTitle id="customized-dialog-title-critical-success">
                        <Typography variant="h3">
                            {store.getState().settings?.displayNames?.criticalSuccessFactors || 'Loading'}
                        </Typography>
                    </DialogTitle>
                    <DialogContent dividers>
                        <Grid container direction="column" spacing={2}>
                            <Grid item xs>
                                <TextField
                                    id="new-crit-success"
                                    variant="outlined"
                                    disabled={snapshotMode || userReadonly}
                                    placeholder={
                                        !snapshotMode && !userReadonly && "Press 'Enter' to Add A New Line Item"
                                    }
                                    fullWidth
                                    value={newCriticalSuccessFactor}
                                    onChange={(e) => setNewCriticalSuccessFactor(e.target.value)}
                                    onFocus={(event) => {
                                        event.target.setAttribute('autocomplete', 'off')
                                    }}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            let newArr = Array.from(newCriticalSuccessFactors)
                                            newArr.push(newCriticalSuccessFactor)
                                            setNewCriticalSuccessFactors(newArr)
                                            setNewCriticalSuccessFactor('')
                                            buildCriticalSuccessFactorMap(newArr)
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid container item xs direction="column" spacing={1}>
                                {newCriticalSuccessFactors.map((row, i) => (
                                    <Grid
                                        container
                                        item
                                        xs
                                        alignItems="center"
                                        justifyContent="space-between"
                                        spacing={2}
                                        wrap="nowrap">
                                        <Grid item>
                                            <Typography variant="title2">{i + 1 + '.'}</Typography>
                                        </Grid>
                                        <Grid item xs>
                                            <TextField
                                                id={'crit-success-' + i}
                                                disabled={snapshotMode || userReadonly}
                                                multiline
                                                fullWidth
                                                variant="filled"
                                                value={criticalSuccessFactorsMap[row]}
                                                onFocus={(event) => {
                                                    event.target.setAttribute('autocomplete', 'off')
                                                }}
                                                onChange={(e) => {
                                                    updateCriticalSuccessFactorsMap(e.target.value, row)
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs="auto">
                                            {!snapshotMode && !userReadonly && (
                                                <IconButton>
                                                    <ClearIcon
                                                        onClick={() => {
                                                            let newMap = {
                                                                ...criticalSuccessFactorsMap,
                                                            }
                                                            delete newMap[row]
                                                            setCriticalSuccessFactorsMap(newMap)
                                                            let newArr = Array.from(newCriticalSuccessFactors)
                                                            newArr.splice(i, 1)
                                                            setNewCriticalSuccessFactors(newArr)
                                                        }}
                                                    />
                                                </IconButton>
                                            )}
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>

                            <Grid container item alignItems="center">
                                <Grid item>
                                    <Typography variant="title2">Uploaded Files</Typography>
                                </Grid>
                                <Grid item xs="auto">
                                    <List>
                                        {selectedFiles.map((fileName, index) => (
                                            <ListItem key={index}>
                                                <ListItemText primary={fileName} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Grid>
                                {!userReadonly && !snapshotMode && (
                                    <Grid item xs="auto">
                                        <IconButton component="label">
                                            <input
                                                id="file-upload"
                                                type="file"
                                                hidden
                                                onChange={onCriticalSuccessFactorsFileChange}
                                                multiple
                                            />
                                            <SvgIcon component={AddCircle} color="primary" inheritViewBox />
                                        </IconButton>
                                    </Grid>
                                )}

                                {/* <Grid item>
                                                    {criticalSuccessFactorsInfo?.originalFileName && (
                                                        <p>{criticalSuccessFactorsInfo?.originalFileName}</p>
                                                    )}
                                                </Grid> */}
                            </Grid>

                            <Grid container item>
                                {criticalSuccessFactorsFiles.map((file, i) => (
                                    <Grid container spacing={1} alignItems="center" wrap="nowrap">
                                        <Grid item xs="auto">
                                            <Typography variant="chip">{i + 1}.</Typography>
                                        </Grid>
                                        <Grid item xs="auto">
                                            <Chip
                                                label={<Typography variant="chip">{file.originalFileName}</Typography>}
                                            />
                                        </Grid>
                                        <Grid container item spacing={1} xs="auto">
                                            <Grid item xs="auto">
                                                <IconButton
                                                    aria-label="download"
                                                    color="primary"
                                                    size="small"
                                                    href={
                                                        (process.env.REACT_APP_BACKEND_LINK
                                                            ? process.env.REACT_APP_BACKEND_LINK
                                                            : '') +
                                                        '/api/file/any/' +
                                                        strategy._id +
                                                        '/' +
                                                        file.atlasFileType +
                                                        '/' +
                                                        i
                                                    }
                                                    target="_">
                                                    <SvgIcon component={Download} alt="Download Icon" inheritViewBox />
                                                </IconButton>
                                            </Grid>
                                            <Grid item xs="auto">
                                                {!userReadonly && !snapshotMode && (
                                                    <IconButton
                                                        aria-label="delete"
                                                        color="primary"
                                                        size="small"
                                                        onClick={() => {
                                                            setOpenDeleteModal(true)
                                                            setToDelete([file.s3Name, file.atlasFileType])
                                                        }}>
                                                        <SvgIcon
                                                            component={DeleteBin}
                                                            alt="Delete Trashcan"
                                                            inheritViewBox
                                                        />
                                                    </IconButton>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleCriticalSuccessClose} variant="outlined" color="primary">
                            {snapshotMode || userReadonly ? 'Close' : 'Cancel'}
                        </Button>
                        {!snapshotMode && !userReadonly && (
                            <Button autoFocus onClick={handleCriticalSuccessSave} variant="contained" color="primary">
                                Save
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>
                <Dialog
                    fullWidth
                    maxWidth="lg"
                    onClose={handleMarketClose}
                    aria-labelledby="customized-dialog-title-market"
                    open={openMarket}>
                    <DialogTitle id="customized-dialog-title-market">
                        <Typography variant="h3">
                            {(store.getState().settings?.displayNames?.marketAccessGoals &&
                                store.getState().settings?.displayNames?.marketAccessGoals) ||
                                'Loading'}
                        </Typography>
                    </DialogTitle>
                    <DialogContent dividers>
                        <Grid container spacing={3}>
                            <Grid container item>
                                <TextField
                                    id="new-mag"
                                    disabled={snapshotMode || userReadonly}
                                    placeholder={!snapshotMode && !userReadonly && "Press 'Enter' to Add New Item"}
                                    fullWidth
                                    value={newMarketAccessGoalText}
                                    onChange={(e) => setNewMarketAccessGoalText(e.target.value)}
                                    onFocus={(event) => {
                                        event.target.setAttribute('autocomplete', 'off')
                                    }}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            let newArr = Array.from(newMarketAccessGoals)
                                            newArr.push({
                                                title: newMarketAccessGoalText,
                                                priority: 'medium',
                                                notes: '',
                                                summary: '',
                                                leverValue: '',
                                            })
                                            setNewMarketAccessGoals(newArr)
                                            setNewCriticalSuccessFactor('')
                                            setNewMarketAccessGoalText('')
                                            buildMarketAccessGoalMap(newArr)
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid container item spacing={1}>
                                {Object.keys(newMarketAccessGoals).map((row, i) => (
                                    <Grid container item key={'compRow' + i} direction="column" xs={6} wrap="nowrap">
                                        <Grid container item spacing={1}>
                                            <Grid item>
                                                <Typography variant="title2">{i + 1 + '.'}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle1">
                                                    {newMarketAccessGoals[row].title}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container alignItems="center">
                                            <Grid item xs>
                                                <TextField
                                                    id={'goal' + i}
                                                    disabled={snapshotMode || userReadonly}
                                                    multiline
                                                    fullWidth
                                                    value={newMarketAccessGoals[i].summary}
                                                    onChange={(e) => {
                                                        let newArr = Array.from(newMarketAccessGoals)
                                                        newArr.splice(i, 1, {
                                                            title: newMarketAccessGoals[i].title,
                                                            priority: newMarketAccessGoals[i].priority,
                                                            notes: newMarketAccessGoals[i].notes,
                                                            summary: e.target.value,
                                                            leverValue: newMarketAccessGoals[i].leverValue,
                                                        })
                                                        setNewMarketAccessGoals(newArr)
                                                    }}
                                                    onFocus={(event) => {
                                                        event.target.setAttribute('autocomplete', 'off')
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs="auto">
                                                {!snapshotMode && !userReadonly && (
                                                    <IconButton>
                                                        <ClearIcon
                                                            onClick={() => {
                                                                let newMap = {
                                                                    ...marketAccessGoalsMap,
                                                                }
                                                                delete newMap[row.title]
                                                                setMarketAccessGoalsMap(newMap)
                                                                let newArr = Array.from(newMarketAccessGoals)
                                                                newArr.splice(i, 1)
                                                                setNewMarketAccessGoals(newArr)
                                                            }}
                                                        />
                                                    </IconButton>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                            <Grid container item alignItems="center">
                                <Grid item>
                                    <Typography variant="title2">Uploaded Files</Typography>
                                </Grid>
                                <Grid item xs="auto">
                                    <List>
                                        {selectedFiles.map((fileName, index) => (
                                            <ListItem key={index}>
                                                <ListItemText primary={fileName} />
                                            </ListItem>
                                        ))}
                                    </List>
                                    <Grid item>
                                        {marketAccessGoalsInfo?.originalFileName && (
                                            <Typography variant="chip">
                                                {marketAccessGoalsInfo?.originalFileName}
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                                {!userReadonly && !snapshotMode && (
                                    <Grid item xs="auto">
                                        <IconButton component="label">
                                            <input
                                                id="file-upload"
                                                type="file"
                                                hidden
                                                onChange={onMarketAccessGoalsFileChange}
                                                multiple
                                            />
                                            <SvgIcon component={AddCircle} color="primary" inheritViewBox />
                                        </IconButton>
                                    </Grid>
                                )}
                            </Grid>
                            <Grid container item>
                                {marketAccessGoalsFiles.map((file, i) => (
                                    <Grid container spacing={1} alignItems="center" wrap="nowrap">
                                        <Grid item xs="auto">
                                            <Typography variant="chip">{i + 1}.</Typography>
                                        </Grid>
                                        <Grid item xs="auto">
                                            <Chip
                                                label={<Typography variant="chip">{file.originalFileName}</Typography>}
                                            />
                                        </Grid>
                                        <Grid container item spacing={1} xs="auto">
                                            <Grid item xs="auto">
                                                <IconButton
                                                    aria-label="download"
                                                    color="primary"
                                                    size="small"
                                                    href={
                                                        (process.env.REACT_APP_BACKEND_LINK
                                                            ? process.env.REACT_APP_BACKEND_LINK
                                                            : '') +
                                                        '/api/file/any/' +
                                                        strategy._id +
                                                        '/' +
                                                        file.atlasFileType +
                                                        '/' +
                                                        i
                                                    }
                                                    target="_">
                                                    <SvgIcon component={Download} alt="Download Icon" inheritViewBox />
                                                </IconButton>
                                            </Grid>
                                            <Grid item xs="auto">
                                                {!userReadonly && !snapshotMode && (
                                                    <IconButton
                                                        aria-label="delete"
                                                        color="primary"
                                                        size="small"
                                                        onClick={() => {
                                                            setOpenDeleteModal(true)
                                                            setToDelete([file.s3Name, file.atlasFileType])
                                                        }}>
                                                        <SvgIcon
                                                            component={DeleteBin}
                                                            alt="Delete Trashcan"
                                                            inheritViewBox
                                                        />
                                                    </IconButton>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleMarketClose} variant="outlined" color="primary">
                            {snapshotMode || userReadonly ? 'Close' : 'Cancel'}
                        </Button>
                        {!snapshotMode && !userReadonly && (
                            <Button autoFocus onClick={handleMarketSave} variant="contained" color="primary">
                                Save
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>
                <Dialog
                    fullWidth
                    maxWidth="lg"
                    onClose={handleFifthButtonClickClose}
                    aria-labelledby="customized-dialog-title-fifth"
                    open={open5thButton}>
                    <DialogTitle id="customized-dialog-title-fifth">
                        <Typography variant="h3">
                            {(store.getState().settings?.displayNames?.fifthBoxDashboard &&
                                store.getState().settings?.displayNames?.fifthBoxDashboard) ||
                                'Loading'}
                        </Typography>
                    </DialogTitle>
                    <DialogContent dividers>
                        <Grid container direction="column" spacing={2}>
                            <Grid item xs>
                                <TextField
                                    id="new-fifth-box"
                                    variant="outlined"
                                    disabled={snapshotMode || userReadonly}
                                    placeholder={
                                        !snapshotMode && !userReadonly && "Press 'Enter' to Add A New Line Item"
                                    }
                                    fullWidth
                                    value={newFifthButtonItem}
                                    onChange={(e) => setNewFifthButtonItem(e.target.value)}
                                    onFocus={(event) => {
                                        event.target.setAttribute('autocomplete', 'off')
                                    }}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            let newArr = Array.from(newFifthButtonValues)
                                            newArr.push(newFifthButtonItem)
                                            setNewFifthButtonValues(newArr)
                                            setNewFifthButtonItem('')
                                            buildFifthButtonValuesMap(newArr)
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid container item xs direction="column" spacing={1}>
                                {newFifthButtonValues.map((row, i) => (
                                    <Grid
                                        container
                                        item
                                        xs
                                        alignItems="center"
                                        justifyContent="space-between"
                                        spacing={2}
                                        wrap="nowrap">
                                        <Grid item>
                                            <Typography variant="title2">{i + 1 + '.'}</Typography>
                                        </Grid>
                                        <Grid item xs>
                                            <TextField
                                                id={'fifth-' + i}
                                                disabled={snapshotMode || userReadonly}
                                                multiline
                                                fullWidth
                                                variant="filled"
                                                value={fifthButtonMap[row]}
                                                onFocus={(event) => {
                                                    event.target.setAttribute('autocomplete', 'off')
                                                }}
                                                onChange={(e) => {
                                                    updateFifthButtonValuesMap(e.target.value, row)
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs="auto">
                                            {!snapshotMode && !userReadonly && (
                                                <IconButton>
                                                    <ClearIcon
                                                        onClick={() => {
                                                            let newMap = {
                                                                ...fifthButtonMap,
                                                            }
                                                            delete newMap[row]
                                                            setFifthButtonMap(newMap)
                                                            let newArr = Array.from(newFifthButtonValues)
                                                            newArr.splice(i, 1)
                                                            setNewFifthButtonValues(newArr)
                                                        }}
                                                    />
                                                </IconButton>
                                            )}
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                            <Grid container item alignItems="center">
                                <Grid item>
                                    <Typography variant="title2">Uploaded Files</Typography>
                                </Grid>
                                <Grid item xs="auto">
                                    <List>
                                        {selectedFiles.map((fileName, index) => (
                                            <ListItem key={index}>
                                                <ListItemText primary={fileName} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Grid>
                                {!userReadonly && !snapshotMode && (
                                    <Grid item xs="auto">
                                        <IconButton component="label">
                                            <input
                                                id="file-upload"
                                                type="file"
                                                hidden
                                                onChange={onOptionalFifthBoxFileChange}
                                                multiple
                                            />
                                            <SvgIcon component={AddCircle} color="primary" inheritViewBox />
                                        </IconButton>
                                    </Grid>
                                )}
                            </Grid>
                            <Grid container item>
                                {optionalFifthBoxFiles.map((file, i) => (
                                    <Grid container spacing={1} alignItems="center" wrap="nowrap">
                                        <Grid item xs="auto">
                                            <Typography variant="chip">{i + 1}.</Typography>
                                        </Grid>
                                        <Grid item xs="auto">
                                            <Chip
                                                label={<Typography variant="chip">{file.originalFileName}</Typography>}
                                            />
                                        </Grid>
                                        <Grid container item spacing={1} xs="auto">
                                            <Grid item xs="auto">
                                                <IconButton
                                                    aria-label="download"
                                                    color="primary"
                                                    size="small"
                                                    href={
                                                        (process.env.REACT_APP_BACKEND_LINK
                                                            ? process.env.REACT_APP_BACKEND_LINK
                                                            : '') +
                                                        '/api/file/any/' +
                                                        strategy._id +
                                                        '/' +
                                                        file.atlasFileType +
                                                        '/' +
                                                        i
                                                    }
                                                    target="_">
                                                    <SvgIcon component={Download} alt="Download Icon" inheritViewBox />
                                                </IconButton>
                                            </Grid>
                                            <Grid item xs="auto">
                                                {!userReadonly && !snapshotMode && (
                                                    <IconButton
                                                        aria-label="delete"
                                                        color="primary"
                                                        size="small"
                                                        onClick={() => {
                                                            setOpenDeleteModal(true)
                                                            setToDelete([file.s3Name, file.atlasFileType])
                                                        }}>
                                                        <SvgIcon
                                                            component={DeleteBin}
                                                            alt="Delete Trashcan"
                                                            inheritViewBox
                                                        />
                                                    </IconButton>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleFifthButtonClickClose} variant="outlined" color="primary">
                            {snapshotMode || userReadonly ? 'Close' : 'Cancel'}
                        </Button>
                        {!snapshotMode && !userReadonly && (
                            <Button autoFocus onClick={handleFifthButtonsSave} variant="contained" color="primary">
                                Save
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={openDeleteModal}
                    onClose={() => setOpenDeleteModal(false)}
                    aria-labelledby="alert-dialog-title4"
                    aria-describedby="alert-dialog-description4">
                    <DialogTitle id="alert-dialog-slide-title2">
                        <Typography variant="h4">Confirm</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <Typography variant="h4">Are you sure you want to delete this file?</Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenDeleteModal(false)} variant="outlined">
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                if (toDelete && toDelete.length && toDelete.length === 2) {
                                    removeFile(toDelete[0], toDelete[1])
                                } else {
                                    console.log('Failed to set delete variable')
                                }
                                setOpenDeleteModal(false)
                            }}>
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </Fade>
    )
}

const Dashboard = connect(null, mapDispatchToProps)(ConnectedDashboard)

export default Dashboard
