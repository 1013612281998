export const COLORS = {
    primary: '#0049EA',
    lipstickRed: '#C30B31',
    salmon: '#FF6B70',
    sunshade: '#FF9C2B',
    brightSun: '#FFD748',
    caribbeanGreen: '#07CC9E',
    screamingGreen: '#80F785',
    magenta: '#E4349B',
    ultraPink: '#E37FFF',
    purpleHeart: '#5C34E4',
    lavender: '#B27FFF',
    butterflyBlue: '#389BFF',
    malibu: '#55B8F9',
    black: '#000000',
    gravel: '#4D4D4D',
    granite: '#808080',
    tuna: '#5E6169',
    pastelGrey: '#CCCCCC',
    greyGoose: '#D3D5DB',
    quillGrey: '#F2F2F4',
    lightQuillGrey: '#F6F6F7',
    white: '#FFFFFF',

    darkContentBackground: '#1B1C1D',

    sanofiActivityPlanned: '#23004C',
    sanofiActivityInitiated: '#7A00AE',
    sanofiActivityOnHold: '#007FAD',
    sanofiActivityCancelled: '#DA3A16',
    sanofiActivityCompleted: '#268500',
}
