import React, { useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import IconButton from '@mui/material/IconButton'
import { ReactComponent as PanelIcon } from '../../assets/images/responsive-design-expand-1.svg'
import { ReactComponent as EditIcon } from '../../assets/images/Edit_Icon.svg'
import { ReactComponent as EditIconDark } from '../../assets/images/Edit_Icon_Dark.svg'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import '../../style/detailed-view.scss'
import ActivityDetailedView from '../Activities/ActivityDetailedView'
import VMDetailedView from '../ValueMessages/VMDetailedView'
import EvidenceDetailedView from '../Evidence/EvidenceDetailedView'
import StrategicImperativeDetailedView from '../StrategicImperatives/StrategicImperativeDetailedView'
import { useSelector } from 'react-redux'
import * as _ from 'underscore'
import { Button, SvgIcon } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'
import { ArrowBackIos } from '@mui/icons-material'
import Tooltip from '@mui/material/Tooltip'

const DeleteModal = ({ openDeleteModal, Transition, handleDeleteModalClose, deleteFunction, confirmationMessage }) => (
    <Dialog
        open={openDeleteModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDeleteModalClose}
        aria-labelledby="alert-dialog-slide-title6"
        aria-describedby="alert-dialog-slide-description6">
        <DialogTitle id="alert-dialog-slide-title6">
            <Typography variant="h4">{confirmationMessage}</Typography>
        </DialogTitle>
        <DialogActions>
            <Button onClick={handleDeleteModalClose} variant="outlined-sm-button">
                Cancel
            </Button>
            <Button onClick={deleteFunction} variant="contained-sm-button">
                Delete
            </Button>
        </DialogActions>
    </Dialog>
)

export default function TablePanel({
    title,
    data,
    type,
    closePanel,
    openPanelAction,
    updateParent,
    saveFunction,
    navigationStack,
}) {
    const [openPanel, setOpenPanel] = useState(false)
    const [editing, setEditing] = useState(false)
    const snapshotMode = useSelector((state) => (state.strategy ? !!state.strategy.sourceId : false))
    const userReadonly = useSelector((state) => (state.userRecord ? !!state.userRecord.readonly : false))
    const [triggerSave, setTriggerSave] = useState(0)
    const [triggerDelete, setTriggerDelete] = useState(0)
    const [triggerCancel, setTriggerCancel] = useState(0)
    const isDarkMode = useSelector((state) => (state?.userRecord ? state?.userRecord?.isDarkMode : true))

    const toggleDrawer = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        setOpenPanel(open)
    }

    function handlePanelClose() {
        toggleDrawer(false)
        closePanel()
    }

    useEffect(() => {
        if (openPanelAction) {
            setOpenPanel(true)
        }
    }, [])

    const typePathMapping = {
        evidence: '/evidence',
        activity: '/activities',
        valueMessage: '/value-messages',
        strategicImperative: '/strategic-imperatives',
    }

    return (
        <>
            <IconButton onClick={toggleDrawer(true)}>
                <PanelIcon />
            </IconButton>
            <SwipeableDrawer
                transitionDuration={400}
                anchor="right"
                open={openPanel}
                onClose={handlePanelClose}
                onOpen={toggleDrawer(true)}
                PaperProps={{ sx: { width: '80%', backgroundColor: isDarkMode ? '#363943' : 'white' } }}>
                <Box sx={{ minWidth: '50vw', backgroundColor: isDarkMode ? '#363943' : 'white' }} role="presentation">
                    <DialogTitle sx={{ p: 2 }} id="customized-dialog-title">
                        <Grid container alignItems="center" justifyContent="space-between">
                            <Grid container item xs="auto">
                                {!_.isEmpty(navigationStack) ? (
                                    <Link
                                        component={RouterLink}
                                        underline="none"
                                        color="secondary"
                                        to={{
                                            pathname: navigationStack[navigationStack.length - 1].path,
                                            autoOpenData: {
                                                isAutoOpenPanel: true,
                                                autoOpenData: navigationStack[navigationStack.length - 1].data,
                                                previousDataStack: navigationStack.slice(0, navigationStack.length - 1),
                                            },
                                        }}>
                                        <Tooltip
                                            enterDelay={600}
                                            enterNextDelay={600}
                                            title="Back to linked data"
                                            arrow>
                                            <IconButton>
                                                <ArrowBackIos color="primary" />
                                            </IconButton>
                                        </Tooltip>
                                    </Link>
                                ) : (
                                    <></>
                                )}
                            </Grid>
                            <Grid container alignItems="center" justifyContent="flex-end" xs="auto" spacing={2}>
                                <Grid item xs="auto">
                                    {editing ? (
                                        <Grid container item spacing={1}>
                                            <Grid item>
                                                <Button
                                                    onClick={() => setTriggerDelete((prev) => prev + 1)}
                                                    aria-label="delete"
                                                    variant="no-outline-sm-button">
                                                    Delete
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    onClick={() => setTriggerCancel((prev) => prev + 1)}
                                                    aria-label="cancel"
                                                    variant="outlined-sm-button">
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    onClick={() => setTriggerSave((prev) => prev + 1)}
                                                    aria-label="save"
                                                    variant="contained-sm-button">
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        !snapshotMode &&
                                        !userReadonly && (
                                            <Grid item xs="auto">
                                                <IconButton
                                                    aria-label="edit"
                                                    onClick={() => setEditing(true)}
                                                    size="large">
                                                    <SvgIcon
                                                        component={isDarkMode ? EditIconDark : EditIcon}
                                                        inheritViewBox
                                                        sx={{ fill: 'none' }}
                                                        fontSize="inherit"
                                                    />
                                                </IconButton>
                                            </Grid>
                                        )
                                    )}
                                </Grid>
                                <Grid item xs="auto">
                                    <IconButton
                                        onClick={handlePanelClose}
                                        onKeyDown={handlePanelClose}
                                        aria-label="close"
                                        size="small"
                                        sx={{
                                            color: '#4D4D4D',
                                            border: '1px solid #4D4D4D',
                                            backgroundColor: 'white',
                                        }}>
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent
                        dividers
                        sx={{ backgroundColor: isDarkMode ? '#363943' : 'white', borderBottom: 'none', p: 0 }}>
                        <Grid container spacing={2}>
                            <Grid container item xs={12} xlg={6}>
                                {type === 'activity' && (
                                    <ActivityDetailedView
                                        activity={data}
                                        forceParentRefresh={updateParent}
                                        closePanel={handlePanelClose}
                                        setEditing={setEditing}
                                        editing={editing}
                                        triggerSave={triggerSave}
                                        triggerDelete={triggerDelete}
                                        triggerCancel={triggerCancel}
                                        navigationStack={navigationStack}
                                    />
                                )}
                                {type === 'valueMessage' && (
                                    <VMDetailedView
                                        valueMessage={data}
                                        forceParentRefresh={updateParent}
                                        closePanel={handlePanelClose}
                                        setEditing={setEditing}
                                        editing={editing}
                                        triggerSave={triggerSave}
                                        triggerDelete={triggerDelete}
                                        triggerCancel={triggerCancel}
                                        navigationStack={navigationStack}
                                    />
                                )}
                                {type === 'evidence' && (
                                    <EvidenceDetailedView
                                        evidence={data}
                                        forceParentRefresh={updateParent}
                                        closePanel={handlePanelClose}
                                        setEditing={setEditing}
                                        editing={editing}
                                        triggerSave={triggerSave}
                                        triggerDelete={triggerDelete}
                                        triggerCancel={triggerCancel}
                                        navigationStack={navigationStack}
                                    />
                                )}
                                {type === 'strategicImperative' && (
                                    <StrategicImperativeDetailedView
                                        strategicImperative={data}
                                        forceParentRefresh={updateParent}
                                        closePanel={handlePanelClose}
                                        setEditing={setEditing}
                                        editing={editing}
                                        triggerSave={triggerSave}
                                        triggerDelete={triggerDelete}
                                        triggerCancel={triggerCancel}
                                        navigationStack={navigationStack}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <Divider style={{ border: 'none' }} />
                </Box>
            </SwipeableDrawer>
        </>
    )
}
