import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import { StyledTableRow } from '../Support/CustomElements'
import Fade from '@mui/material/Fade'
import TablePanel from '../Support/TablePanel'
import * as _ from 'underscore'
import store from '../../redux/store'
import { useAuth0 } from '../../contexts/auth0-context'
import callAPI from '../../API/callSecureAPI'
import endpoints from '../../API/endpoints'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { connect, useSelector } from 'react-redux'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import IconButton from '@mui/material/IconButton'
import { ReactComponent as OngoingIcon } from '../../assets/images/orange_triangle.svg'
import { ReactComponent as CompletedIcon } from '../../assets/images/green_circle.svg'
import { setStrategy, setValueMessageTableNumberOfItems } from '../../redux/actions'
import Typography from '@mui/material/Typography'
import {
    DraggableComponent,
    DroppableComponent,
    EnhancedTableHead,
    newOrder,
    timeout,
    stableSort,
    getComparator,
} from '../../utilities/helpers'
import { SvgIcon } from '@mui/material'
import { GlobalStyles } from '@mui/material'
import { EmptyPlaceholder } from '../../shared/components/EmptyPlaceholder'
import { AtlasAIButton } from '../../shared/components/AtlasAIButton'
import { COLORS } from '../../style/colors'
import { useIsDarkMode } from '../../shared/hooks/useIsDarkMode'

function createData(value, known, evidence, activities, id, order, panel, isCountrySpecific) {
    return { value, known, evidence, activities, id, order, panel, isCountrySpecific }
}

function mapDispatchToProps(dispatch) {
    return {
        setStrategy: (strat) => dispatch(setStrategy(strat)),
        setVMTableNumberOfRows: (num) => dispatch(setValueMessageTableNumberOfItems(num)),
    }
}

function ConnectedVMTable(props) {
    const snapshotMode = useSelector((state) => (state.strategy ? !!state.strategy.sourceId : false))
    const userReadonly = useSelector((state) => (state.userRecord ? !!state.userRecord.readonly : false))

    const { isAuthenticated, getTokenSilently } = useAuth0()
    const [page, setPage] = React.useState(0)
    const [dataRows, setDataRows] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [forceRefresh, setForceRefresh] = useState(false)
    const [vmData, setVMData] = useState({})
    const [settings, setSettings] = useState({})
    const [isPanelOpen, setIsPanelOpen] = useState(false)
    const [valueMessageForPanel, setValueMessageForPanel] = useState(
        props?.autoOpenState?.isAutoOpenPanel ? props.autoOpenState.autoOpenData : {}
    )
    const [updateTableFromPanel, setUpdateTableFromPanel] = useState(false)
    const [navigationStack, setNavigationStack] = useState([])
    const selectedMarket = useSelector((state) => state.selectedMarket)
    const [isAILoading, setIsAILoading] = useState(false)
    const [isAIGeneratedValueStatement, setIsASGeneratedValueStatement] = useState(false)

    const isDarkMode = useIsDarkMode();

    // Columns declared as const weren't loading Store in time for render.
    // eslint-disable-next-line no-unused-vars

    const [columns, setColumns] = useState([
        { id: 'index', label: '', align: '', headPad: '' },
        { id: 'value', label: 'Title', align: 'left', headPad: 2.5 },
        { id: 'known', label: 'Status', align: 'center', headPad: 2.5 },
        {
            id: 'evidence',
            label:
                (store.getState().settings?.displayNames?.evidence &&
                    store.getState().settings?.displayNames?.evidence) ||
                'Loading',
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
            headPad: 2.5,
        },
        {
            id: 'activities',
            label:
                (store.getState().settings?.displayNames?.activities &&
                    store.getState().settings?.displayNames?.activities) ||
                'Loading',
            align: 'center',
            format: (value) => value.toLocaleString('en-US'),
            headPad: 2.5,
        },
        { id: 'panel', label: '', align: '', headPad: 2.5 },
    ])

    const [valueStatements, setValueStatements] = useState({})
    const [newValueStatements, setNewValueStatements] = useState({})
    const [order, setOrder] = useState('asc')
    const [orderBy, setOrderBy] = useState('')

    const [fullDataSet, setFullDataSet] = useState([])
    const itemsToDisplay = useSelector((state) => state.valueMessagesTableNumberOfItems || 10)

    const { newFormData, filterCategory } = props

    const [isAIEnabled, setIsAIEnabled] = useState(false)

    async function authenticateAI() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        let incomingOrg = await callAPI(
            endpoints.getOrganizationById.url + store.getState().strategy.organizationId,
            endpoints.getOrganizationById.method,
            token
        )
        if (incomingOrg.success && incomingOrg?.data?.isAIEnabled) {
            setIsAIEnabled(true)
        }
    }

    async function onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return
        }

        const newItems = newOrder(dataRows, result.source.index, result.destination.index)
        setDataRows(newItems)

        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        let ids = newItems.map((row) => row.id)
        await callAPI(endpoints.reorderVMs.url, endpoints.reorderVMs.method, token, ids)
        setOrderBy('index')
        setForceRefresh(true)
    }

    async function getSettings() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        let incomingSettings = await callAPI(
            endpoints.getSettingsByOrganizationId.url + store.getState().strategy.organizationId,
            endpoints.getSettingsByOrganizationId.method,
            token
        )
        if (incomingSettings.success) {
            setSettings(incomingSettings.data)
        }
    }

    useEffect(() => {
        if (isAuthenticated) {
            getSettings()
            authenticateAI()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated])

    let vmDisplayName = store.getState().settings?.displayNames?.valueMessage || 'Loading'

    useEffect(() => {
        // This fires when component mounts and any time the VM list is modified
        if (forceRefresh) {
            setForceRefresh(false)
        }

        if (!isAuthenticated) {
            return
        }

        if (updateTableFromPanel) {
            setUpdateTableFromPanel(false)
        }

        if (store.getState().strategy) {
            setValueStatements(store.getState().strategy.valueStatements)
            setNewValueStatements(store.getState().strategy.valueStatements)
        }
        getVMData()
        checkForAutoPanel()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceRefresh, isAuthenticated, updateTableFromPanel])

    useEffect(() => {
        checkForAutoPanel()
    }, [props])

    useEffect(() => {
        setForceRefresh(true)
    }, [selectedMarket])

    useEffect(() => {
        if (filterCategory === 'All') {
            createDataRows(fullDataSet)
        } else {
            filter(filterCategory)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fullDataSet])

    useEffect(() => {
        // track 'newFormData' from props for changes
        // if it changes, it means the Create Button is sending data
        if (!_.isEmpty(newFormData)) {
            newValueMessage().then(() => {
                setForceRefresh(true)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newFormData])

    useEffect(() => {
        if (filterCategory === 'All') {
            filterAll()
            return
        }
        filter(filterCategory)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterCategory])

    async function checkForAutoPanel() {
        if (_.get(props, ['autoOpenState', 'isAutoOpenPanel'], false)) {
            setValueMessageForPanel(props.autoOpenState.autoOpenData)
            setNavigationStack(props.autoOpenState.previousDataStack ? props.autoOpenState.previousDataStack : [])
            await timeout(700)
            setIsPanelOpen(true)
        }
    }

    async function getVMData() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        let incomingVMs = await callAPI(
            endpoints.getValueMessagesForTable.url + store.getState().strategy._id,
            endpoints.getValueMessagesForTable.method,
            token
        )

        if (incomingVMs.success) {
            let vmsToSet = incomingVMs.data.length > 0 ? incomingVMs.data.reverse() : []
            if (store.getState().selectedMarket !== 'Global') {
                vmsToSet = vmsToSet.filter((vm) => vm.localMarkets.includes(store.getState().selectedMarket))
            } else if (store.getState().selectedMarket === 'Global') {
                vmsToSet = vmsToSet.filter((vm) => !vm.isCountrySpecific)
            }
            setFullDataSet(vmsToSet)
        }
        setLoaded(true)
        let vms = {}
        for (let vm of incomingVMs.data) {
            vms[vm._id] = vm
        }
        setVMData(vms)
        if (valueMessageForPanel) {
            setValueMessageForPanel(vms[valueMessageForPanel._id])
        }
    }

    async function newValueMessage() {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        await callAPI(endpoints.createValueMessage.url, endpoints.createValueMessage.method, token, newFormData)
    }

    function createDataRows(data) {
        let arr = []
        data.forEach((vm) => {
            let dataRow = createData(
                // vm.priority,
                vm.name,
                vm.known,
                vm.linkedEvidence.length,
                vm.linkedActivities.length,
                vm._id,
                vm.order,
                '',
                vm.isCountrySpecific ? vm.isCountrySpecific : false
            )
            arr.push(dataRow)
        })
        arr.sort((a, b) => a.order - b.order)
        setDataRows(arr)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        props.setVMTableNumberOfRows(+event.target.value)
        setPage(0)
    }

    const filterAll = () => {
        createDataRows(fullDataSet)
    }

    const filter = (categoryToFilter) => {
        createDataRows(fullDataSet.filter((vm) => vm.category === categoryToFilter).reverse())
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const handleSaveValueStatements = async () => {
        let token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        let strategy = store.getState().strategy
        let newStrategy = {
            positioningStatement: strategy.positioningStatement,
            product: strategy.product,
            indication: strategy.indication,
            organizationId: strategy.organizationId,
            TPPS3Name: strategy.TPPS3Name,
            TPPMimeType: strategy.TPPMimeType,
            TPPOriginalFileName: strategy.TPPOriginalFileName,
            keyMarkets: strategy.keyMarkets,
            targetLaunchDate: strategy.targetLaunchDate,
            competitiveLandscape: strategy.competitiveLandscape,
            strategicImperatives: strategy.strategicImperatives,
            TPP: strategy.TPP,
            gapLevers: strategy.gapLevers,
            criticalSuccessFactors: strategy.criticalSuccessFactors,
            valueStatements: newValueStatements,
            optionalFifthBoxDashboard: strategy.optionalFifthBoxDashboard,
            TPPFiles: strategy.TPPFiles,
            competitiveLandscapeFiles: strategy.competitiveLandscapeFiles,
            strategicImperativesFiles: strategy.strategicImperativesFiles,
            optionalFifthBoxFiles: strategy.optionalFifthBoxFiles,
            criticalSuccessFactorsFiles: strategy.criticalSuccessFactorsFiles,
        }
        await callAPI(endpoints.updateStrategy.url + strategy._id, endpoints.updateStrategy.method, token, newStrategy)
        let updatedStrategy = await callAPI(
            endpoints.getStrategy.url + store.getState().strategy._id,
            endpoints.getStrategy.method,
            token
        )
        props.setStrategy(updatedStrategy.data)
        setValueStatements(newValueStatements)
        setIsASGeneratedValueStatement(false)
        setForceRefresh(true)
    }

    function handlePanelOpen(id) {
        setValueMessageForPanel(vmData[id])
        setIsPanelOpen(true)
    }

    function handlePanelClose() {
        setValueMessageForPanel({})
        setIsPanelOpen(false)
        setNavigationStack([])
    }

    const globalStyles = (
        <GlobalStyles
            styles={{
                '.MuiTableCell-root': {
                    borderBottom: 'none !important',
                },
            }}
        />
    )

    async function generateAI() {
        if (isAILoading) {
            return;
        }

        setIsAILoading(true)

        const token = await getTokenSilently({ audience: 'https://atlas.aesara.com' })
        const res = await callAPI('/api/value-message/ai/value-statements', 'post', token, {
            category: filterCategory,
            strategyId: store.getState().strategy._id,
        })

        if (res.success) {
            setNewValueStatements({ ...newValueStatements, [filterCategory]: res.message })
            setIsASGeneratedValueStatement(true)
        }

        setIsAILoading(false)
    }

    const isValueMessagesDataEmpty = !dataRows?.length;

    return (
        <>
            {globalStyles}
            <Fade in={loaded} timeout={800}>
                <Grid container item xs>
                    {filterCategory !== 'All' && (
                        <Grid container item direction="row" mb={4}>
                            <Grid item xs={isAIEnabled ? 11 : 12}>
                                <TextField
                                    id="standard-multiline-flexible"
                                    fullWidth
                                    variant="filled"
                                    multiline
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    label={
                                        (settings?.displayNames?.valueMessage &&
                                            settings?.displayNames?.valueMessage +
                                                ' Summary: ' +
                                                settings['vmCategoryDisplayNames'][filterCategory]) ||
                                        'Loading'
                                    }
                                    helperText={
                                        isAIGeneratedValueStatement
                                            ? '*Generated with ATLAS AI - please verify accuracy.'
                                            : ' '
                                    }
                                    disabled={snapshotMode || userReadonly}
                                    value={newValueStatements[filterCategory]}
                                    onChange={(e) => {
                                        let newObj = { ...newValueStatements }
                                        newObj[filterCategory] = e.target.value
                                        setNewValueStatements(newObj)
                                    }}
                                />
                            </Grid>
                            {isAIEnabled && (
                                <Grid item xs={1} display="flex" alignItems="center" justifyContent="center" pl={1} pb={3}>
                                    <AtlasAIButton
                                        name="Generate Value Statement"
                                        onClick={generateAI}
                                        isLoading={isAILoading}
                                        isGenerated={isAIGeneratedValueStatement}
                                    />
                                </Grid>
                            )}
                            {!_.isEqual(valueStatements, newValueStatements) && (
                                <Grid container item justifyContent="flex-end" alignItems="center" spacing={2}>
                                    <Grid item>
                                        <Button
                                            variant="no-outline-sm-button"
                                            sx={{ color: isDarkMode ? COLORS.tuna : COLORS.gravel }}
                                            onClick={() => {
                                                setNewValueStatements(valueStatements)
                                                setIsASGeneratedValueStatement(false)
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="no-outline-sm-button"
                                            onClick={handleSaveValueStatements}
                                        >
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    )}
                    {isValueMessagesDataEmpty && (
                        <EmptyPlaceholder
                            title={`No ${vmDisplayName} to display`}
                            subtitle={`Click 'Add New' on the left panel to create a new ${vmDisplayName}`}
                        />
                    )}
                    {!isValueMessagesDataEmpty && (
                        <>
                            <TableContainer>
                                <Table stickyHeader aria-label="sticky table" size="small">
                                    <EnhancedTableHead
                                        columns={columns}
                                        order={order}
                                        orderBy={orderBy}
                                        align={columns.align}
                                        onRequestSort={handleRequestSort}
                                    />
                                    <TableBody
                                        component={DroppableComponent(onDragEnd)}
                                        sx={{ backgroundColor: isDarkMode ? '#363943' : '#F5F5F6' }}>
                                        {stableSort(dataRows, getComparator(order, orderBy))
                                            .slice(page * itemsToDisplay, page * itemsToDisplay + itemsToDisplay)
                                            .map((row, index) => {
                                                return (
                                                    <StyledTableRow
                                                        component={DraggableComponent(
                                                            row.id,
                                                            index,
                                                            userReadonly || snapshotMode
                                                        )}
                                                        key={row.id}
                                                        tabIndex={-1}
                                                        sx={{ backgroundColor: isDarkMode ? '#1B1D20' : '#FAFAFA' }}
                                                        hover>
                                                        {columns.map((column) => {
                                                            const value = row[column.id]
                                                            if (column.id === 'known') {
                                                                return (
                                                                    <TableCell
                                                                        key={column.id}
                                                                        align={column.align}
                                                                        sx={{ border: 'none' }}>
                                                                        <SvgIcon
                                                                            fontSize="small"
                                                                            component={value ? CompletedIcon : OngoingIcon}
                                                                            inheritViewBox
                                                                        />
                                                                    </TableCell>
                                                                )
                                                            } else if (column.id === 'index') {
                                                                return (
                                                                    <TableCell
                                                                        hover
                                                                        key={column.id}
                                                                        align={column.align}
                                                                        sx={{
                                                                            border: 'none',
                                                                            borderTopLeftRadius: index === 0 ? 14 : 0,
                                                                            borderBottomLeftRadius:
                                                                                index ===
                                                                                dataRows.slice(
                                                                                    page * itemsToDisplay,
                                                                                    (page + 1) * itemsToDisplay
                                                                                ).length -
                                                                                1
                                                                                    ? 14
                                                                                    : 0,
                                                                        }}>
                                                                        <Typography variant="body9" sx={{ color: '#808080' }}>
                                                                            {index + page * itemsToDisplay + 1}.
                                                                        </Typography>
                                                                    </TableCell>
                                                                )
                                                            } else if (column.id === 'value' && row['isCountrySpecific']) {
                                                                return (
                                                                    <TableCell
                                                                        key={column.id}
                                                                        align={column.align}
                                                                        sx={{ border: 'none' }}>
                                                                        <Typography variant="body1">
                                                                            {column.format && typeof value === 'number'
                                                                                ? column.format(value)
                                                                                : value}
                                                                        </Typography>
                                                                    </TableCell>
                                                                )
                                                            } else if (column.id === 'panel') {
                                                                return (
                                                                    <TableCell
                                                                        key={column.id}
                                                                        align={column.align}
                                                                        sx={{
                                                                            border: 'none',
                                                                            borderTopRightRadius: index === 0 ? 14 : 0,
                                                                            borderBottomRightRadius:
                                                                                index ===
                                                                                dataRows.slice(
                                                                                    page * itemsToDisplay,
                                                                                    (page + 1) * itemsToDisplay
                                                                                ).length -
                                                                                1
                                                                                    ? 14
                                                                                    : 0,
                                                                        }}
                                                                        onClick={() => handlePanelOpen(row.id)}>
                                                                        <IconButton>
                                                                            <ArrowForwardIosIcon color="primary" />
                                                                        </IconButton>
                                                                    </TableCell>
                                                                )
                                                            } else {
                                                                return (
                                                                    <TableCell
                                                                        key={column.id}
                                                                        align={column.align}
                                                                        sx={{ border: 'none' }}>
                                                                        <Typography
                                                                            variant={
                                                                                column.format && typeof value === 'number'
                                                                                    ? 'h4'
                                                                                    : 'body1'
                                                                            }
                                                                            color={
                                                                                column.format && typeof value === 'number'
                                                                                    ? 'primary'
                                                                                    : ''
                                                                            }>
                                                                            {column.format && typeof value === 'number'
                                                                                ? column.format(value)
                                                                                : value}
                                                                        </Typography>
                                                                    </TableCell>
                                                                )
                                                            }
                                                        })}
                                                    </StyledTableRow>
                                                )
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50, 100]}
                                component="div"
                                count={dataRows.length}
                                rowsPerPage={itemsToDisplay}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </>
                    )}
                    {isPanelOpen && (
                        <TablePanel
                            title={valueMessageForPanel?.name}
                            data={valueMessageForPanel}
                            type={'valueMessage'}
                            closePanel={handlePanelClose}
                            openPanelAction={true}
                            updateParent={setUpdateTableFromPanel}
                            navigationStack={navigationStack}
                        />
                    )}
                </Grid>
            </Fade>
        </>
    )
}

const VMTable = connect(null, mapDispatchToProps)(ConnectedVMTable)

export default VMTable
