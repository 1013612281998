import React, { useEffect, createContext, useMemo } from 'react'
import '../style/App.scss'
import { useAuth0 } from '../contexts/auth0-context'
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles'
import Dashboard from '../components/Dashboard/Dashboard'
import { BrowserRouter as Router, Route, Switch, useHistory, Redirect } from 'react-router-dom'
import Evidence from '../components/Evidence/Evidence'
import Activities from '../components/Activities/Activities'
import StrategicImperatives from '../components/StrategicImperatives/StrategicImperatives'
import GapAnalysis from '../components/GapAnalysis/GapAnalysis'
import ValueMessages from '../components/ValueMessages/ValueMessages'
import Layout from '../components/Support/Layout'
import HelpCenter from '../components/Support/Help'
import MetricsGrid from '../components/Metrics/MetricsGrid'
import '../style/styles.scss'
import StrategySelection from '../components/StrategySelection/StrategySelection'
import Settings from '../components/Settings/Settings'
import Snapshots from '../components/Snapshots/Snapshots'
import SearchLandingPage from '../components/Search/SearchLandingPage'
import Tracelinks from '../components/Tracelinks/Tracelink'
import { CssBaseline } from '@mui/material'
import { connect, useSelector } from 'react-redux'
import { setSearchQuery, setSearchResults } from '../redux/actions'
import Logout from '../components/Support/Logout'
import { useDynamicFavicon } from './useDynamicFavicon'
import { COLORS } from '../style/colors'

const ColorModeContext = createContext({
    toggleColorMode: () => {},
})

function mapDispatchToProps(dispatch) {
    return {
        setSearchQuery: (query) => dispatch(setSearchQuery(query)),
    }
}

function ConnectedApp(props) {
    const history = useHistory()
    const { isLoading, user, loginWithRedirect, logout } = useAuth0()

    useDynamicFavicon();

    // constructed like this so that in the brief moments of App uncertainty
    // the palette defaults to dark mode
    const isDarkMode = useSelector((state) =>
        !state.userRecord ? true : state?.userRecord?.isDarkMode === undefined ? true : state?.userRecord?.isDarkMode
    )

    const getDesignTokens = (mode: PaletteMode) => ({
        typography: {
            fontFamily: 'Acumin Pro, Arial',
            h1: {
                fontSize: '28px',
                fontWeight: '600',
            },
            h2: {
                fontSize: '22px',
                fontWeight: '600',
            },
            h3: {
                fontSize: '22px',
                fontWeight: '500',
            },
            h4: {
                fontSize: '20px',
                fontWeight: '500',
            },
            h5: {
                fontSize: '18px',
                fontWeight: '600',
            },
            h6: {
                fontSize: '12px',
                fontWeight: '300',
            },
            subtitle1: {
                fontSize: '18px',
                fontWeight: '400',
            },
            subtitle2: {
                fontSize: '16px',
                fontWeight: '500',
            },
            subtitle3: {
                fontSize: '14px',
                fontWeight: '500',
            },
            subtitle4: {
                fontSize: '12px',
                fontWeight: '600',
            },
            subtitle5: {
                fontSize: '12px',
                fontWeight: '400',
            },
            title1: {
                fontSize: '18px',
                fontWeight: '500',
            },
            title2: {
                fontSize: '16px',
                fontWeight: '600',
            },
            body1: {
                fontSize: '18px',
                fontWeight: '500',
            },
            body2: {
                fontSize: '18px',
                fontWeight: '400',
            },
            body3: {
                fontSize: '16px',
                fontWeight: '500',
            },
            body4: {
                fontSize: '16px',
                fontWeight: '400',
            },
            body5: {
                fontSize: '14px',
                fontWeight: '500',
            },
            body6: {
                fontSize: '14px',
                fontWeight: '400',
            },
            body7: {
                fontSize: '32px',
                fontWeight: '300',
            },
            body8: {
                fontSize: '24px',
                fontWeight: '500',
            },
            body9: {
                fontSize: '22px',
                fontWeight: '300',
            },
            button: {
                fontSize: '16px',
                fontWeight: '600',
            },
            menu1: { fontSize: '18px', fontWeight: '600' },
            menu2: { fontSize: '16px', fontWeight: '400' },
            chip: { fontSize: '14px', fontWeight: '400' },
            caption: {
                fontSize: 18,
                fontWeight: '400',
            },
            overline: {
                fontSize: 14,
                fontWeight: '400',
            },
        },
        palette: {
            isDarkMode,
            ...(isDarkMode
                ? {
                      //   palette values for dark mode
                      primary: {
                          main: '#0b95ff',
                      },
                      type: 'dark',
                      secondary: {
                          main: '#ffffff',
                      },
                      background: {
                          paper: '#1f2127',
                          default: COLORS.darkContentBackground,
                          table: '#0049EA',
                      },
                      divider: 'rgba(255, 255, 255,.2)',
                      text: {
                          primary: '#ffffff',
                          secondary: '#0b95ff',
                      },
                      error: {
                          main: '#3736f4',
                      },
                  }
                : {
                      //   palette values for light mode
                      primary: {
                          main: '#0049EA',
                      },
                      type: 'light',
                      secondary: {
                          main: '#000000',
                      },
                      divider: 'rgba(0,0,0,.2)',
                      text: {
                          primary: '#4D4D4D',
                          secondary: '#000000',
                      },
                      error: {
                          main: '#3736f4',
                      },
                  }),
        },
    })

    let theme = useMemo(
        () =>
            createTheme(getDesignTokens(isDarkMode), {
                components: {
                    MuiFormHelperText: {
                        styleOverrides: {
                            root: {
                                color: 'grey',
                                fontStyle: 'italic',
                            },
                        },
                    },
                    MuiAppBar: {
                        styleOverrides: {
                            root: {
                                backgroundColor: isDarkMode ? '#000000' : '#F2F6FE',
                            },
                        },
                    },
                    MuiChip: {
                        styleOverrides: {
                            defaultProps: {
                                backgroundColor: isDarkMode ? '#000000' : '#EBF1FD',
                            },
                        },
                    },
                    MuiAccordionSummary: {
                        styleOverrides: {
                            root: {
                                backgroundColor: isDarkMode ? '#000000' : '#EBF1FD',
                            },
                        },
                    },
                    MuiDialog: {
                        styleOverrides: {
                            paper: {
                                borderRadius: '16px',
                            },
                        },
                    },
                    MuiAccordion: {
                        variants: [
                            {
                                props: { variant: 'rounded-bottom' },
                                style: {
                                    boxShadow: 'none',
                                    width: '100%',
                                    borderBottomLeftRadius: '24px',
                                    borderBottomRightRadius: '24px',
                                    backgroundColor: isDarkMode ? '#5E6169' : '#F5F5F6',
                                },
                            },
                        ],
                    },
                    MuiGrid: {
                        variants: [
                            {
                                props: { variant: 'rounded-top-grid' },
                                style: {
                                    backgroundColor: isDarkMode ? '#5E6169' : '#F5F5F6',
                                    borderTopLeftRadius: '16px',
                                    borderTopRightRadius: '16px',
                                },
                            },
                        ],
                    },
                    MuiButton: {
                        variants: [
                            {
                                props: { variant: 'no-outline-sm-button' }, // Custom variant name
                                style: {
                                    borderRadius: '14px',
                                    width: '113px',
                                    height: '52px',
                                    color: isDarkMode ? 'white' : '#0049EA',
                                    boxShadow: 'none',
                                    border: 'none',
                                },
                            },
                            {
                                props: { variant: 'outlined-sm-button' }, // Custom variant name
                                style: {
                                    borderRadius: '14px',
                                    width: '113px',
                                    height: '52px',
                                    color: isDarkMode ? 'white' : '#0049EA',
                                    border: isDarkMode ? '1px solid #389BFF' : '1px solid #0049EA',
                                    boxShadow: 'none',
                                },
                            },
                            {
                                props: { variant: 'contained-sm-button' }, // Custom variant name
                                style: {
                                    borderRadius: '14px',
                                    width: '113px',
                                    height: '52px',
                                    color: 'white',
                                    backgroundColor: isDarkMode ? '#389BFF' : '#0049EA',
                                    border: '1px solid #0049EA',
                                    boxShadow: 'none',
                                    '&:hover': {
                                        backgroundColor: isDarkMode ? '#55B8F9' : '#002984',
                                        boxShadow: 3,
                                    },
                                },
                            },
                            {
                                props: { variant: 'contained-big-button' },
                                style: {
                                    borderRadius: '16px',
                                    width: '250px',
                                    height: '52px',
                                    color: 'white',
                                    backgroundColor: isDarkMode ? '#389BFF' : '#0049EA',
                                    border: '1px solid #0049EA',
                                    boxShadow: 'none',
                                    '&:hover': {
                                        backgroundColor: isDarkMode ? '#55B8F9' : '#002984',
                                        boxShadow: 3,
                                    },
                                },
                            },
                            {
                                props: { variant: 'contained-link-unlink' },
                                style: {
                                    borderRadius: '9px',
                                    width: '72px',
                                    height: '32px',
                                    color: 'white',
                                    backgroundColor: isDarkMode ? '#389BFF' : '#0049EA',
                                    border: '1px solid #0049EA',
                                    boxShadow: 'none',
                                    '&:hover': {
                                        backgroundColor: isDarkMode ? '#1968AF' : '#002984',
                                        boxShadow: 3,
                                    },
                                },
                            },
                            {
                                props: { variant: 'contained-sort' },
                                style: {
                                    borderRadius: '12px',
                                    minWidth: 'auto',
                                    height: '42px',
                                    color: 'white',
                                    backgroundColor: isDarkMode ? '#389BFF' : '#0049EA',
                                    border: '1px solid #0049EA',
                                    boxShadow: 'none',
                                    '&:hover': {
                                        backgroundColor: isDarkMode ? '#1968AF' : '#002984',
                                        boxShadow: 3,
                                    },
                                },
                            },
                        ],
                        styleOverrides: {
                            root: {
                                '&.Mui-disabled': {
                                    color: 'inherit',
                                    opacity: 0.26,
                                },
                            },
                        },
                    },
                },
                props: {
                    MuiAppBar: {
                        color: 'inherit',
                    },
                    MuiChip: {
                        color: 'inherit',
                    },
                },
            }),
        [isDarkMode]
    )
    theme = responsiveFontSizes(theme)

    useEffect(() => {
        ;(async function login() {
            if (!isLoading && !user) {
                await loginWithRedirect()
            }
        })()
    }, [isLoading, user, loginWithRedirect])

    function navigateAwayFromSearch() {
        props.setSearchQuery('')
    }

    return (
        <ColorModeContext.Provider value={isDarkMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Router>
                    <Layout>
                        <Switch>
                            <Route path="/roadmaps" exact component={StrategySelection} />
                            <Route path="/" exact component={Dashboard} />
                            <Route path="/evidence" component={Evidence} />
                            <Route path="/value-messages" component={ValueMessages} />
                            <Route path="/activities" component={Activities} />
                            <Route path="/strategic-imperatives" component={StrategicImperatives} />
                            <Route path="/gap-analysis" component={GapAnalysis} />
                            <Route path="/settings" component={Settings} />
                            <Route path="/snapshots" component={Snapshots} />
                            <Route path="/tracelink" component={Tracelinks} />
                            <Route path="/help" component={HelpCenter} />
                            <Route path="/metrics" component={MetricsGrid} />
                            <Route path="/reset" component={Logout} />
                            <Route path="/search" component={SearchLandingPage} onLeave={navigateAwayFromSearch} />
                            <Redirect from="*" to="/" />
                        </Switch>
                    </Layout>
                </Router>
            </ThemeProvider>
        </ColorModeContext.Provider>
    )
}

const App = connect(null, mapDispatchToProps)(ConnectedApp)

export default App
