import { makeStyles } from '@mui/styles'

export const useEmptyPlaceholderStyles = makeStyles((theme) => {
    return {
        container: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            // @ts-expect-error theme is not typed properly
            gap: theme.spacing(1),
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
        },
    }
})
