import React, { ComponentProps, useMemo } from 'react';
import clsx from 'clsx';
import { SvgIcon, Tooltip } from '@mui/material';
import { ReactComponent as RefreshIcon } from '../../../assets/images/refresh-white.svg';
import { ReactComponent as SparkleIcon } from '../../../assets/images/sparkle.svg';

import styles from './AtlasAIButton.module.scss';
import Box from '@mui/material/Box';
import { useIsDarkMode } from '../../hooks/useIsDarkMode'

type Props = {
    name?: string;
    size?: number;
    onClick?: () => void;
    isLoading?: boolean;
    isGenerated?: boolean;
    tooltipProps?: Omit<ComponentProps<typeof Tooltip>, 'title'>;
};

export const AtlasAIButton = ({ name, onClick, size = 50, isLoading, isGenerated, tooltipProps }: Props) => {
    const isDarkMode = useIsDarkMode();

    const isClickable = !!onClick;

    const title = useMemo(() => {
        if (!isClickable) {
            return null;
        }

        if (isLoading) {
            return 'Generating...';
        }

        if (isGenerated) {
            return 'Regenerate with ATLAS AI';
        }

        return `${name ?? 'Generate'} with ATLAS AI`;
    }, [isLoading, isGenerated, name]);

    const buttonSx = useMemo(() => ({
        height: size,
        width: size,
    }), [size]);

    const iconSx = useMemo(() => ({
        height: size * 0.5,
        width: size * 0.5,
    }), [size]);


    return (
        <Tooltip title={title} enterDelay={750} placement="bottom" {...tooltipProps}>
            <Box
                onClick={onClick}
                className={clsx(styles.button, isClickable && styles.clickable, isDarkMode && styles.dark, isLoading && styles.loading)}
                sx={buttonSx}>
                <SvgIcon
                    component={isGenerated ? RefreshIcon : SparkleIcon}
                    className={clsx(
                        styles.icon,
                        isLoading && styles.loading,
                        isGenerated && styles.refresh,
                        isGenerated && !isLoading && styles.generated
                    )}
                    sx={iconSx}
                    inheritViewBox
                />
            </Box>
        </Tooltip>
    );
};
