export let initialState = {
    strategy: {
        keyMarkets: [],
        competitiveLandscape: [],
        strategicImperatives: [],
        _id: '',
        positioningStatement: '',
        product: '',
        indication: '',
        gapLevers: [],
        TPPMimeType: '',
        TPPOriginalFileName: '',
        targetLaunchDate: '',
        TPPS3Name: '',
        organizationId: '',
        createdAt: '',
        updatedAt: '',
        __v: 0,
    },
    valueMessageFilterMapping: 0,
    userRecord: {},
    selectedMarket: 'Global',
    gapAnalysisView: 'gapAssessment',
    activitiesTableNumberOfItems: 10,
    evidenceTableNumberOfItems: 10,
    valueMessagesTableNumberOfItems: 10,
    siTableNumberOfItems: 10,
    settings: {},
    selectedSharepointFile: {},
    searchResults: [],
    searchQuery: '',
    order: 0,
    autoOpenPanel: {
        type: '',
        data: '',
        isEnabled: false,
    },
    freeTrialStatus: {
        isFreeTrial: false,
        freeTrialStartDate: null,
    }
}

export function rootReducer(state = initialState, action) {
    if (action.type === 'SET_STRATEGY') {
        return Object.assign({}, state, {
            strategy: action.payload,
        })
    } else if (action.type === 'SET_VM_FILTER') {
        return Object.assign({}, state, {
            valueMessageFilterMapping: action.payload,
        })
    } else if (action.type === 'SET_USER_RECORD') {
        return Object.assign({}, state, {
            userRecord: action.payload,
        })
    } else if (action.type === 'SET_SELECTED_MARKET') {
        return Object.assign({}, state, {
            selectedMarket: action.payload,
        })
    } else if (action.type === 'SET_GAP_ANALYSIS_VIEW') {
        return Object.assign({}, state, {
            gapAnalysisView: action.payload,
        })
    } else if (action.type === 'SET_SETTINGS') {
        return Object.assign({}, state, {
            settings: action.payload,
        })
    } else if (action.type === 'SET_VM_TABLE_NUMBER') {
        return Object.assign({}, state, {
            valueMessagesTableNumberOfItems: action.payload,
        })
    } else if (action.type === 'SET_ACTIVITY_TABLE_NUMBER') {
        return Object.assign({}, state, {
            activitiesTableNumberOfItems: action.payload,
        })
    } else if (action.type === 'SET_EVIDENCE_TABLE_NUMBER') {
        return Object.assign({}, state, {
            evidenceTableNumberOfItems: action.payload,
        })
    } else if (action.type === 'SET_SI_TABLE_NUMBER') {
        return Object.assign({}, state, {
            siTableNumberOfItems: action.payload,
        })
    } else if (action.type === 'SET_ONEDRIVE_FILE') {
        return Object.assign({}, state, {
            selectedSharepointFile: action.payload,
        })
    } else if (action.type === 'SET_SEARCH_RESULTS') {
        return Object.assign({}, state, {
            searchResults: action.payload,
        })
    } else if (action.type === 'SET_SEARCH_QUERY') {
        return Object.assign({}, state, {
            searchQuery: action.payload,
        })
    } else if (action.type === 'LOGOUT') {
        return undefined
    } else if (action.type === 'SET_AUTOOPEN_PANEL') {
        return Object.assign({}, state, {
            autoOpenPanel: action.payload,
        })
    } else if (action.type === 'SET_FREE_TRIAL_STATUS') {
        return Object.assign({}, state, {
            freeTrialStatus: {
                isFreeTrial: action.payload.isFreeTrial,
                freeTrialStartDate: action.payload.freeTrialStartDate,
            },
        })
    }

    return state
}
